import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useFormContext, Controller } from "react-hook-form";

import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import FormControl from "@mui/material/FormControl";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import format from 'date-fns/format';
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

import { styled } from '@mui/material/styles';

import PrintFormProcessed from "../print/PrintFormProcessed"
const CardStyled = styled(Card)(({ theme }) => ({
    background: theme.palette.background.default
}));
function Tab7(props) {

    const { activeTab, tempData, handelClickPrint, printRef } = props;

    if (activeTab != 5) {
        return (<></>);
    }

    const methods = useFormContext();
    const dispatch = useDispatch();

    const { control, formState, setValue, trigger, watch } = methods;
    const { errors } = formState;

    const form = watch();

    return (
        <>
            <div className="w-full">
                <div className="p-9 pb-0 w-1/2 min-w-58rem max-600px:min-w-0 max-600px:w-full m-auto mb-48 mt-32 max-600px:mt-10 ">
                    <Box sx={{ display: 'flex flex-col', justifyContent: 'center' }}>
                        <Box sx={{
                            bgcolor: '#F1FFF7',
                            m: 1,
                            border: 2,
                            width: '100%',
                            height: '100%',
                            margin: 'unset',
                            borderColor: '#00843C',
                            borderRadius: 1,
                            py: 2
                        }}>
                            <CardContent className='p-0 pb-0' sx={{ pb: '0px!important' }}>
                                <Typography variant="h4 font-bold text-green-700 text-center" component="div">
                                    Booking has been added successfully and is now waiting for Approval.
                                </Typography>
                            </CardContent>
                        </Box>
                    </Box>

                    <CardStyled className="flex flex-col shadow-0 mt-10" elevation={0}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                                <DialogContent classes={{ root: 'py-10 px-10 pb-0 ' }}>
                                    <div className=" flex justify-between mt-10 mx-16 items-center">
                                        <Typography className='text-14 font-semibold'>
                                            Booking Reference #: {tempData.referenceNum}
                                        </Typography>
                                        <IconButton
                                            color="inherit" className="rounded-0 py"
                                            onClick={handelClickPrint}
                                        >
                                            <Icon color="inherit">print</Icon>
                                        </IconButton>
                                    </div>
                                    <div className='flex flex-row max-600px:flex-wrap'>
                                        <div className='flex flex-col mx-16'>
                                            <div className='flex'>
                                                <ImageList sx={{ width: 120, height: 120 }} cols={1} className="overflow-hidden rounded-md">
                                                    <ImageListItem>
                                                        <img className="max-w-none w-auto h-full" src={form.itemPhotoUrl ? form.itemPhotoUrl : 'assets/images/ecommerce/product-image-placeholder.png'} alt={form.name} loading="lazy" />
                                                    </ImageListItem>
                                                </ImageList>
                                            </div>
                                        </div>
                                        <div className='flex flex-col mx-16 relative'>
                                            <Typography className='text-14 font-semibold'>
                                                {form.itemCode}
                                            </Typography>
                                            <Typography className=' text-18 font-bold'>
                                                {form.itemName}
                                            </Typography>
                                            <div className='absolute max-600px:relative bottom-0 truncate'>
                                                <Typography className='text-15 font-bold'>
                                                    <span className="text-15 font-medium">CMI Rate :</span> ${form.itemRate} / (30 mins)
                                                </Typography>
                                                <Typography className='text-15 font-bold'>
                                                    <span className="text-15 font-medium">Total :</span> ${form.total}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex flex-row justify-between mt-24 max-600px:mt-0 mb-16 max-600px:flex-wrap-reverse'>
                                        <div className='flex flex-col mx-16 '>
                                            <Typography className=' text-14 font-semibold mt-10'>
                                                <span className=" text-14 font-medium">Name :</span> {form.name}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                <span className=" text-14 font-medium">Email :</span> {form.email}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                <span className=" text-14 font-medium">Contact :</span> {form.contact}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                <span className=" text-14 font-medium">Department :</span> {form.department}
                                            </Typography>
                                        </div>
                                        <div className='flex flex-col mx-16'>
                                            <Typography className=' text-14 font-semibold mt-10'>
                                                <span className=" text-14 font-medium">Start :</span> {form.start ? format(new Date(form.start), 'EEEE, MMMM dd, yyyy') : ""} {form.start ? format(new Date(form.start), 'hh:mm a') : ""} - {form.end ? format(new Date(form.end), 'hh:mm a') : ""}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                <span className=" text-14 font-medium">Duration : </span> {form.duration}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                <span className=" text-14 font-medium">Time of Booking :</span> {form.timeOfBooking ? format(new Date(form.timeOfBooking), 'MMMM dd, yyyy hh:mm a') : ""}
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className='flex flex-col mx-16 mb-24'>
                                        <Typography className=' text-14 font-semibold'>
                                            Purpose/Instructions
                                        </Typography>
                                        <Typography className='text-14  '>
                                            {form.purpose}
                                        </Typography>
                                    </div>

                                </DialogContent>
                            </Grid>
                        </Box>
                    </CardStyled>
                </div>
            </div>
            <PrintFormProcessed printRef={printRef} form={form} tempData={tempData} />
        </>
    );
}

export default Tab7;
