import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "app/graphql/booking/items_graphql";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { showMessage } from "app/store/fuse/messageSlice";

export const getItems = createAsyncThunk('bookingApp/bookingItems/getItems', async () => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.list
    });
    const data = response.data.data.bookingItemsList;
    return data;
});

export const getItemByID = createAsyncThunk(
    "bookingApp/bookingItems/getItemByID",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getItemByID,
            variables: {
                itemId: params.id,
            },
        });

        if (response.data.data.bookingItemById) {
            const data = response.data.data.bookingItemById;

            return data === undefined ? null : data;
        }
    }
);

export const getItemsPaginate = createAsyncThunk('bookingApp/bookingItems/getItemsPaginate', async (filter) => {
    filter.exclude = filter.exclude ? filter.exclude : 'false';

    const response = await axios.post(CONFIG.API + "/api/", {
        query: (filter.noPhoto ? query.listPaginateNoPhoto : (filter.withTerms ? query.listPaginateWTerms : query.listPaginate)),
        variables: filter
    });
    const data = response.data.data.bookingItemsListPaginate;
    return data;
});

export const removeItems = createAsyncThunk(
    'bookingApp/bookingItems/removeItems',
    async (itemsIds, { dispatch, getState }) => {

        await itemsIds.map(async (id) => {

            await axios.post(CONFIG.API + "/api/", {
                query: mutation.delete,
                variables: {
                    id: id
                }
            });

        })

        return itemsIds;
    }
);

export const saveFileImportItems = createAsyncThunk(
    "bookingApp/bookingItems/saveFileImportItems",
    async (filesData, { dispatch, getState }) => {


        await uploadSvc({
            file: filesData.file,
            destination_file_name: "booking/files/csv-" + randomString() + "-" + filesData.file.name
        }).then(async (result) => {

            if (result.status === 200 && result.data.status === "success") {

                var uploadData = result.data.uploadData
                uploadData['module'] = 'booking-file-import-items';

                const response = await axios.post(CONFIG.API + "/api/", {
                    query: mutationUpload.create,
                    variables: {
                        data: JSON.stringify(uploadData),
                    },
                });

                if (response.data.data.createUpload.status == "success") {
                    const response = await axios.post(CONFIG.API + "/external/booking/import/booking/items");

                    if (response.data.status == 'error') {

                        dispatch(
                            showMessage({
                                message: "Something went wrong please check your import file.",
                                autoHideDuration: 5000, //ms
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "right",
                                },
                                variant: "error",
                            })
                        );
                    } else {

                        dispatch(
                            showMessage({
                                message: "File has been successfully imported.",
                                autoHideDuration: 5000, //ms
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "right",
                                },
                                variant: "success",
                            })
                        );

                    }

                    return response;
                }

            }

        });

    }
);

const saveImages = (itemData) => {

    return new Promise(async (resolve, reject) => {

        if (itemData.images.length) {

            var newImages = [];

            itemData.images.map(async (image, index) => {

                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image
                    })
                } else {
                    if (itemData.featuredImageId == image.fileId) {
                        itemData.featuredImagePath = image.path;
                    }
                }

            });

            if (newImages.length) {

                newImages.map(async (image, index) => {

                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name: "booking/item/item-" + randomString() + "-" + (image.image.file ? image.image.file.name : image.image.fileName)
                    }).then(async (result) => {

                        if (result.status === 200 && result.data.status === "success") {

                            var uploadData = result.data.uploadData
                            uploadData['module'] = 'booking-item';
                            uploadData['file_id'] = image.image.id;

                            const response = await axios.post(CONFIG.API + "/api/", {
                                query: mutationUpload.create,
                                variables: {
                                    data: JSON.stringify(uploadData),
                                },
                            });

                            if (response.data.data.createUpload.status == "success") {
                                var upload = response.data.data.createUpload.upload;

                                if (itemData.featuredImageId == image.image.id) {
                                    itemData.featuredImagePath = upload.path;
                                }

                                itemData.images[image.index].upload_id = upload.id;
                                itemData.images[image.index].file_name = upload.fileName;
                                itemData.images[image.index].file_id = upload.fileId;
                                itemData.images[image.index].file_type = upload.fileType;
                                itemData.images[image.index].path = upload.path;
                                itemData.images[image.index].module = upload.module;
                                // itemData.images[image.index].url = "";



                            }
                        }

                        if (newImages.length == (index + 1)) {
                            resolve(itemData);
                        }


                    });

                });

            } else {
                resolve(itemData)
            }


        } else {

            resolve(itemData);

        }

    });

}

export const saveItem = createAsyncThunk(
    "bookingApp/bookingItems/saveItem",
    async (itemData, { dispatch, getState }) => {

        itemData.publicRate = itemData.publicRate ? parseFloat(itemData.publicRate) : 0;
        itemData.cmiRate = itemData.cmiRate ? parseFloat(itemData.cmiRate) : 0;

        await saveImages(itemData).then(async (itemData) => {

            if (itemData.id) {
                const response = await axios.post(CONFIG.API + "/api/", {
                    query: mutation.edit,
                    variables: {
                        data: JSON.stringify(itemData),
                        id: itemData.id,
                    },
                });

                if (response.data.data.editItems.status != 'error') {
                    dispatch(
                        showMessage({
                            message: "Item has been successfully updated.",
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "success",
                        })
                    );
                    if (!isMain) return;
                    setSaveStatus(true);
                } else {
                    dispatch(
                        showMessage({
                            message: "Something went wrong. Please try again later.",
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "error",
                        })
                    );
                    if (!isMain) return;
                    setSaveStatus(false);
                }

            } else {
                const response = await axios.post(CONFIG.API + "/api/", {
                    query: mutation.create,
                    variables: { data: JSON.stringify(itemData) },
                });

                if (response.data.data.createBookingItems.status != 'error') {
                    dispatch(
                        showMessage({
                            message: "Item has been successfully saved.",
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "success",
                        })
                    );
                } else {
                    dispatch(
                        showMessage({
                            message: "Something went wrong. Please try again later. " + response.data.data.createItems.message,
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "error",
                        })
                    );
                }
            }

        });

    }
);

const bookingItemsAdapter = createEntityAdapter({});

export const { selectAll: selectItems, selectById: selectUserById } =
    bookingItemsAdapter.getSelectors((state) => state.bookingApp.bookingItems);

const bookingItemsSlice = createSlice({
    name: 'bookingApp/bookingItems',
    initialState: bookingItemsAdapter.getInitialState({
        searchText: '',
        typeText: '',
        typeText: '',
        departmentText: '',
    }),
    reducers: {
        setItemsSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: (event) => ({ payload: (!!event ? event.target.value : '') || '' }),
        },
        setItemsTypeText: {
            reducer: (state, action) => {
                state.typeText = action.payload;
            },
            prepare: (event) => ({ payload: (!!event ? event.target.value : '') || '' }),
        },
        setDepartmentText: {
            reducer: (state, action) => {
                state.departmentText = action.payload;
            },
            prepare: (event) => ({ payload: (!!event ? event.target.value : '') || '' }),
        },
    },
    extraReducers: {
        [getItems.fulfilled]: bookingItemsAdapter.setAll,
        [getItemByID.fulfilled]: (state, action) => action.payload,
        [removeItems.fulfilled]: (state, action) =>
            bookingItemsAdapter.removeMany(state, action.payload),
        [saveFileImportItems.fulfilled]: (state, action) => action.payload,
        [saveItem.fulfilled]: (state, action) => action.payload,
    },
});

export const { setItemsSearchText, setItemsTypeText, setDepartmentText } = bookingItemsSlice.actions;

export default bookingItemsSlice.reducer;
