/*
* DateUpdated: 03/21/23
* updatedBy: ico
* Summary: Redesign
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-49278-96
*/
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from "@mui/material/CardContent";
import format from 'date-fns/format';
import { styled } from '@mui/material/styles';
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Stack from '@mui/material/Stack';
import { Divider } from "@mui/material";
const TableUnstyled = styled('table')`
  

  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

`;
function PrintFormProcessed({ printRef, form, tempData }) {

    return (
        <>
            <div className='hidden'>
                <div ref={printRef}>
                    <div className="header-print-posting">
                        <div className='float-left m-10 p-10'>
                            <Typography className="text-18 font-semibold">
                                CMI OSS SYSTEM
                            </Typography>
                            <Typography className="text-16 font-semibold">
                            Booking Reference #: {tempData.referenceNum}
                            </Typography>
                        </div>
                        <div className='float-right m-10 p-10'>
                            <div className="logo">
                                <img width="110" src="assets/images/logos/cmi-logo-blue.png" alt="logo" />
                            </div>
                        </div>
                    </div>

                   

                    <DialogContent classes={{ root: 'px-24 pb-0 ' }}>
                        <div className="container -mt-28 ">
                            <Stack direction="column" >
                                <Typography className="mt-5">Time of Booking: <span className="">{form.timeOfBooking ? format(new Date(form.timeOfBooking), 'MMMM dd, yyyy hh:mm a') : ""}</span></Typography>
                                <Typography className="mt-5">Start: <span className=""> {form.start ? format(new Date(form.start), 'EEEE, MMMM dd, yyyy') : ""} {form.start ? format(new Date(form.start), 'hh:mm a') : ""} - {form.end ? format(new Date(form.end), 'hh:mm a') : ""}</span></Typography>
                                <Stack direction="row" className=" justify-between items-center mt-5 mb-5">
                                    <Typography>Duration: <span className="">{form.duration}</span></Typography>
                                    {/* {(form.total != '0' && form.status.includes("Approved")) && (
                                        <>
                                            <Button variant="text" saze="small" className=" rounded-md capitalize py-0 px-5">
                                                View Invoice |  {form.invoiceStatus}
                                            </Button>
                                        </>
                                    )} */}
                                </Stack>
                                <TableUnstyled>
                                    <tbody>
                                        <tr >
                                            <td width="50%">Name: <span className="font-bold">{form.name}</span></td>
                                            <td width="50%">Email: <span className="font-bold">{form.email}</span></td>
                                        </tr>
                                        <tr >
                                            <td width="50%">Department: <span className="font-bold">{form.department}</span></td>
                                            <td width="50%">Contact: <span className="font-bold">{form.contact}</span></td>
                                        </tr>
                                    </tbody>
                                </TableUnstyled>

                                <Typography className=" font-semibold uppercase mt-10 mb-10">Booking Details</Typography>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <ImageList sx={{ width: 90, height: 90 }} cols={1} className="overflow-hidden rounded-md">
                                        <ImageListItem>
                                            <img className="max-w-none w-auto h-full" src={form.itemPhotoUrl ? form.itemPhotoUrl : 'assets/images/ecommerce/product-image-placeholder.png'} alt={form.name} loading="lazy" />
                                        </ImageListItem>
                                    </ImageList>
                                    <Stack >
                                        <Typography className='text-16 font-bold'>
                                            {form.itemCode} | {form.itemName}
                                        </Typography>
                                        <Typography className='text-14 font-bold'>
                                            <span className="text-14 font-medium">CMI Rate:</span> ${form.itemRate} / (30 mins)
                                        </Typography>
                                        <Typography className='text-14 font-bold'>
                                            <span className="text-14 font-medium">Total:</span> ${form.total}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Divider className=" mt-16" />
                                <Typography className=" font-semibold uppercase mt-10 mb-10">Purpose/Instructions</Typography>
                                <Typography className=" leading-4">{form.purpose}</Typography>
                            </Stack>
                        </div>
                    </DialogContent>
                           
                </div>
            </div>
        </>
    );
}

export default PrintFormProcessed;