const mutation = {
    create: `
        mutation createMotorpoolBooking($data: JSONString!) {
            createMotorpoolBooking (data: $data) {
                status,
                res{
                    referenceNum
                    dateCreated
                },
                message
            }
        }
    `,
    update: `
        mutation updateMotorpoolBooking($data: JSONString!) {
            updateMotorpoolBooking (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    delete: `
        mutation deleteMotorpoolBooking($id: String!) {
            deleteMotorpoolBooking (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
    saveInspectionMotorpool: `
        mutation saveInspectionMotorpool($data: JSONString!) {
            saveInspectionMotorpool (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    resendMotorpoolBooking: `
        mutation resendMotorpoolBooking($data: JSONString!) {
            resendMotorpoolBooking (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    cancelMotorpoolBooking: `
        mutation cancelMotorpoolBooking($data: JSONString!) {
            cancelMotorpoolBooking (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    motorpoolBookingList: `
    query motorpoolBookingList(
        $id: String!, 
    ) {
        motorpoolBookingList(
            id: $id
        ) {
            id
            patronId
            publicId
            itemId
            referenceNum
            itemName
            itemCode
            itemRate
            itemType
            statusInspection
            itemPhotoUrl
            name
            contact
            hasDeffective
            address
            email
            department
            purpose
            timeOfBooking
            startFormatted
            endFormatted
            start
            end
            status
            title
            duration
            total
            backgroundColor
            textColor
            display
            editable
            statusBanned
            dateCreated
            dateUpdated  
            assignedDriver
            driverData
            type
        }
      }
  `,
    motorpoolBookingAvailable: `
    query motorpoolBookingAvailable(
        $id: String!, 
    ) {
        motorpoolBookingAvailable(
            id: $id
        ) {
            id
            patronId
            publicId
            itemId
            referenceNum
            itemName
            itemCode
            itemRate
            itemType
            statusInspection
            itemPhotoUrl
            name
            contact
            hasDeffective
            address
            email
            department
            purpose
            timeOfBooking
            startFormatted
            endFormatted
            start
            end
            status
            title
            duration
            total
            backgroundColor
            textColor
            display
            editable
            statusBanned
            dateCreated
            dateUpdated  
            assignedDriver
            driverData
            type
        }
      }
  `,
    getEventsMotorpoolBooking: `
    query getEventsMotorpoolBooking($fromDate: String!, $toDate: String!) {
        getEventsMotorpoolBooking(fromDate: $fromDate, toDate: $toDate){
            id
            patronId
            publicId
            departmentId
            itemId
            referenceNum
            itemName
            itemCode
            itemRate
            itemType
            itemPhotoUrl
            inspectionRequired
            statusInspection
            hasDeffective
            dateFormatted
            name
            contact
            address
            email
            department
            purpose
            timeOfBooking
            startFormatted
            endFormatted
            start
            end
            status
            title
            duration
            durationFormat
            total
            backgroundColor
            textColor
            display
            editable
            statusBanned
            dateCreated
            dateUpdated  
            type
            approvee {
                id
                name
                email
                position
                department
                level
                status
                bookingTypes
                dateCreated
                dateUpdated
            }
            approvedById
            invoice{
                invoiceNum
                subTotal
                grandTotal
                discount
                invoiceStatus
            }
        }
    }
    `,
    getEventsMotorpoolBookingPaginate: `
    query getEventsMotorpoolBookingPaginate(
            $limit: Int, 
            $keyword: String,  
            $page: Int, 
            $fromDate: String, 
            $toDate: String, 
            $department: String, 
            $type: String, 
            $status: String, 
            $order: String
        ) {
        getEventsMotorpoolBookingPaginate(
            limit: $limit, 
            keyword: $keyword,  
            page: $page, 
            fromDate: $fromDate, 
            toDate: $toDate, 
            department: $department, 
            type: $type, 
            status: $status, 
            order: $order
        ){
            page
            pages
            hasNext
            hasPrev
            totalItems
            itemsPerPage
            objects {  
                id
                patronId
                publicId
                departmentId
                itemId
                referenceNum
                itemName
                itemCode
                itemRate
                itemType
                itemPhotoUrl
                inspectionRequired
                statusInspection
                hasDeffective
                dateFormatted
                name
                contact
                address
                email
                department
                purpose
                timeOfBooking
                startFormatted
                endFormatted
                start
                end
                status
                title
                duration
                durationFormat
                total
                backgroundColor
                textColor
                display
                editable
                statusBanned
                dateCreated
                dateUpdated  
                type
                approvee {
                    id
                    name
                    email
                    position
                    department
                    level
                    status
                    bookingTypes
                    dateCreated
                    dateUpdated
                }
                approvedById
                invoice{
                    invoiceNum
                    subTotal
                    grandTotal
                    discount
                    invoiceStatus
                }
                assignedDriver
                driverData
            }
        }
    }
  `,
    getEventsMotorpoolBookingCrm: `
  query getEventsMotorpoolBookingCrm($customerId: String) {
      getEventsMotorpoolBookingCrm(customerId: $customerId){
          id
          patronId
          publicId
          departmentId
          itemId
          referenceNum
          itemName
          itemCode
          itemRate
          itemType
          itemPhotoUrl
          inspectionRequired
          statusInspection
          hasDeffective
          dateFormatted
          name
          contact
          address
          email
          department
          purpose
          timeOfBooking
          startFormatted
          endFormatted
          start
          end
          status
          title
          duration
          durationFormat
          total
          backgroundColor
          textColor
          display
          editable
          statusBanned
          dateCreated
          dateUpdated  
          type
          approvee {
              id
              name
              email
              position
              department
              level
              status
              bookingTypes
              dateCreated
              dateUpdated
          }
          approvedById
          invoice{
              invoiceNum
              subTotal
              grandTotal
              discount
              invoiceStatus
          }
      }
  }
`,
    getEventsMotorpoolBookingCalendar: `
        query getEventsMotorpoolBooking($fromDate: String!, $toDate: String!) {
            getEventsMotorpoolBooking(fromDate: $fromDate, toDate: $toDate){
                id
                patronId
                publicId
                departmentId
                itemId
                referenceNum
                itemName
                itemCode
                itemRate
                itemType
                itemPhotoUrl
                inspectionRequired
                statusInspection
                hasDeffective
                dateFormatted
                name
                contact
                address
                email
                department
                purpose
                timeOfBooking
                startFormatted
                endFormatted
                start
                end
                status
                title
                duration
                durationFormat
                total
                backgroundColor
                textColor
                display
                statusBanned
                dateCreated
                dateUpdated  
                approvee {
                    id
                    name
                    email
                    position
                    department
                    level
                    status
                    bookingTypes
                    dateCreated
                    dateUpdated
                }
                approvedById
                invoice{
                    invoiceNum
                    subTotal
                    grandTotal
                    discount
                    invoiceStatus
                }
            }
        }
    `,

    getMotorpoolBookingById: `
    query getMotorpoolBookingById(
        $id: String!, 
    ) {
        getMotorpoolBookingById(
            id: $id
        ) {
            id
            patronId
            publicId
            itemId
            referenceNum
            itemName
            itemCode
            itemRate
            itemType
            itemPhotoUrl
            name
            contact
            address
            email
            department
            inspectionRequired
            statusInspection
            hasDeffective
            purpose
            timeOfBooking
            startFormatted
            endFormatted
            start
            end
            status
            title
            duration
            total
            backgroundColor
            textColor
            display
            editable
            statusBanned
            statusBooking
            assignedDriver
            dateCreated
            dateUpdated  
        }
    }
    `,

    getBookingInspectionById: `
    query getBookingInspectionById($bookingId: String!, $inspectionType: String!) {
        getBookingInspectionById(bookingId: $bookingId, inspectionType: $inspectionType) {
            id
            bookingId
            external1
            external2
            external3
            vehicle1
            vehicle2
            vehicle3
            vehicle4
            vehicle5
            vehicle6
            others1
            others2
            others3
            walls
            flooring
            windows
            doors
            electrical
            plumbing
            furniture
            equipment
            littering
            organize
            notes
            dateCreated
            dateUpdated
            images {
                id
                inspectionId
                uploadId
                fileName
                fileType
                fileId
                module
                path
                url
                status
                dateCreated
                dateUpdated
            }
        }
    }
    `,
}

export { mutation, query };