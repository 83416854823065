import * as React from 'react';

import { experimentalStyled as styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { Controller, useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { orange } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import FuseLoading from '@fuse/core/FuseLoading';
import clsx from 'clsx';
import Icon from '@mui/material/Icon';

import { motorpoolVehiclesType } from 'app/main/apps/settings/motorpool/vehicles-type/store/vehiclesTypeSlice';
import { getMotorpoolVehiclesBooking } from "app/main/apps/motorpool/store/motorpoolVehicleSlice";


import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { motion } from 'framer-motion';

const Item = styled('div')(({ theme }) => ({
    '& .productImageFeaturedStar': {
        position: 'absolute',
        top: 0,
        right: 0,
        color: orange[400],
        opacity: 0,
    },

    '& .productImageUpload': {
        transitionProperty: 'box-shadow',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
    },

    '& .productImageItem': {
        transitionProperty: 'box-shadow',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        '&:hover': {
            '& .productImageFeaturedStar': {
                opacity: 0.8,
            },
        },
        '&.featured': {
            pointerEvents: 'none',
            boxShadow: theme.shadows[3],
            '& .productImageFeaturedStar': {
                opacity: 1,
            },
            '&:hover .productImageFeaturedStar': {
                opacity: 1,
            },
        },
    },
}));


function Tab3(props) {

    const { activeTab } = props;

    if (activeTab != 2) {
        return (<></>);
    }

    const methods = useFormContext();
    const dispatch = useDispatch();

    const { formState, control, setValue } = methods;
    const { errors } = formState;

    const [loadingOptions, setLoadingOptions] = useState(false);

    const [valueItem, setValueitem] = useState(0);

    const handleChange = (event, newValue) => {
        setValueitem(newValue);
    };

    const [bookingTypeOptions, setBookingTypeOptions] = useState([]);

    const [bookingListOptions, setBookingListOptions] = useState([]);

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        dispatch(motorpoolVehiclesType()).then((action) => {
            if (action.payload.length > 0) {

                console.log("action.payload", action.payload)
                setBookingTypeOptions(action.payload);

                getBookingItems(action.payload[0].name);

            }
        });

    }, [dispatch])

    function getBookingItems(type) {

        setLoadingOptions(true);

        dispatch(getMotorpoolVehiclesBooking(type)).then((action) => {
            console.log("MotorpoolInventory", action.payload)
            setLoadingOptions(false);
            setBookingListOptions(action.payload)
        });
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-force-tabpanel-${index}`}
                aria-labelledby={`scrollable-force-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
    };

    function a11yProps(index) {
        return {
            id: `scrollable-force-tab-${index}`,
            "aria-controls": `scrollable-force-tabpanel-${index}`
        };
    }

    function setItem(items) {
        console.log("items", items);
        setValue('itemName', items.name);
        setValue('itemRate', items.cmiRate);
        setValue('itemCode', items.plate);
        setValue('itemType', 'Vehicle');
        setValue('itemVehicleType', items.vehicleType);
        setValue('itemPhotoUrl', items.featuredImageUrl);
        setValue('inspectionRequired', items.inspectionRequired);
    }

    return (
        <Item className='flex max-600px:flex-col mt-10 max-600px:mt-5'>
            {/* <FormHelperText error={!!errors.itemId}>{errors?.itemId?.message}</FormHelperText> */}
            <Tabs
                orientation={sm ? "vertical" : "horizontal"}
                allowScrollButtonsMobile
                className="my-16 max-600px:my-0 min-w-fit max-600px:min-w-0"
                value={valueItem}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={'auto'}
                indicatorColor="secondary"
                textColor="primary"
                aria-label="scrollable force tabs"
                wrapped="true"
                // TabIndicatorProps={{
                //     style: { display: 'none' }
                //     }}
                sx={{ borderColor: { xs: "white", sm: 'divider' }, borderRight: 1 }}
            >

                {bookingTypeOptions.map((value, key) => (
                    <Tab
                        onClick={() => { getBookingItems(value.name, key) }}
                        key={key}
                        label={value.name}
                        {...a11yProps(0)}
                        color="primary"
                        style={{ fontSize: 14, }}
                    />
                ))}

            </Tabs>

            {(!loadingOptions && (!bookingTypeOptions.length || !bookingListOptions.length)) && (

                <Table stickyHeader aria-labelledby="tableTitle">
                    <TableBody>
                        <TableRow key="noitems">
                            <TableCell className="p-10">
                                <motion.div
                                    className="flex flex-1 items-center justify-center h-full"
                                    initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
                                >
                                    <Typography color="textSecondary" variant="h5">
                                        There are no vehicles.
                                    </Typography>
                                </motion.div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

            )}

            {loadingOptions ? (
                <div className=" m-auto w-full">
                    <FuseLoading />
                </div>
            ) :
                <TabPanel>
                    <div className="flex sm:justify-start flex-wrap m-auto">
                        <Controller
                            name="itemId"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) =>
                                bookingListOptions.map((items, key) => (

                                    <div className='flex flex-col mx-20 mb-24 max-w-160' key={key}>
                                        <div className='flex'>
                                            <div
                                                onClick={() => { onChange((items.id)); setItem(items) }}
                                                onKeyDown={() => { onChange((items.id)); setItem(items) }}
                                                role="button"
                                                tabIndex={0}
                                                className={clsx(
                                                    'productImageItem flex items-center justify-center relative rounded-md overflow-hidden cursor-pointer outline-none shadow hover:shadow-lg',
                                                    (items.id === value) && 'featured'
                                                )}
                                                key={(items.id)}
                                            >

                                                <Icon className="productImageFeaturedStar absolute left-0 mt-5 ml-5 z-99">star</Icon>
                                                <ImageList sx={{ width: 130, height: 130 }} cols={1} className="overflow-hidden rounded-md">
                                                    <ImageListItem>
                                                        <img className="max-w-none w-auto h-full" src={items.featuredImageUrl ? items.featuredImageUrl : 'assets/images/ecommerce/product-image-placeholder.png'} alt={items.name} loading="lazy" />
                                                    </ImageListItem>
                                                </ImageList>

                                            </div>
                                        </div>
                                        <Typography className='text-12 font-semibold max-w-128'>
                                            {items.plate}
                                        </Typography>
                                        <Typography className='text-13 font-bold w-136 line-clamp-2'>
                                            {items.name}
                                        </Typography>
                                    </div>

                                ))
                            }
                        />

                    </div>
                </TabPanel>
            }

        </Item>
    );
}

export default Tab3;

