import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query, mutation } from "app/main/booking/graphql/booking_graphql";

export const getBookings = createAsyncThunk(
    "booking/getBookings",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.bookingAvailable,
            variables: {
                id: params ? params.id : ""
            }
        });

        if (response.data.data.bookingAvailable) {
            const data = response.data.data.bookingAvailable;

            return data === undefined ? null : data;
        }
    }
);

export const searchPatron = createAsyncThunk(
    "booking/searchPatron",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.searchBookingPatron,
            variables: params
        });

        if (response.data.data.searchBookingPatron) {
            const data = response.data.data.searchBookingPatron;

            return data == undefined ? null : data;
        }
    }
);

export const saveBooking = createAsyncThunk(
    "booking/saveBooking",
    async (params, { dispatch, getState }) => {

        params.startUtc = (params.start ? (new Date(params.start)).toISOString() : null);

        console.log("Booking Data:::", params);

        const response = await axios.post(CONFIG.API + "/api/", {
            query: params.id ? mutation.update : mutation.create,
            variables: { data: JSON.stringify(params) }
        });

        return params.id ? response.data.data.updateBooking : response.data.data.createBooking;

    }
);


const bookingSlice = createSlice({
    name: "booking/booking",
    initialState: null,
    reducers: {
        resetBooking: () => null,
        newBooking: {
            reducer: (state, action) => action.payload,
            prepare: (event) => ({
                payload: {
                    bookingId: "",
                    name: "",
                    contact: "",
                    address: "",
                    email: "",
                    department: "",
                    purpose: "",
                    featuredImageId: "",
                    start: "",
                    end: "",
                    status: "",
                    statusBanned: false
                },
            }),
            extraReducers: {
                [searchPatron.fulfilled]: (state, action) => action.payload,
                [getBookings.fulfilled]: (state, action) => action.payload,
                [saveBooking.fulfilled]: (state, action) => action.payload,
            }
        }
    }
});

export const { newBooking, resetBooking } = bookingSlice.actions;

export default bookingSlice.reducer;
