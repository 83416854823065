const mutation = {
    create: `
    mutation createMotorpoolBooking($data: JSONString!) {
        createMotorpoolBooking (data: $data) {
            status,
            res{
                referenceNum
                dateCreated
            },
            message
        }
    }
    `,
    update: `
        mutation updateMotorpoolBooking($data: JSONString!) {
            updateMotorpoolBooking (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    resendMotorpoolBooking: `
        mutation resendMotorpoolBooking($data: JSONString!) {
            resendMotorpoolBooking (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    cancelMotorpoolBooking: `
        mutation cancelMotorpoolBooking($data: JSONString!) {
            cancelMotorpoolBooking (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    saveBookingFeedback: `
        mutation saveBookingFeedback($data: JSONString!) {
            saveBookingFeedback (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    motorpoolBookingList: `
    query motorpoolBookingList(
        $id: String!, 
    ) {
        motorpoolBookingList(
            id: $id
        ) {
            id
            patronId
            publicId
            itemId
            referenceNum
            itemName
            itemCode
            itemRate
            itemType
            statusInspection
            itemPhotoUrl
            name
            contact
            hasDeffective
            address
            email
            department
            purpose
            timeOfBooking
            startFormatted
            endFormatted
            start
            end
            status
            title
            duration
            total
            backgroundColor
            textColor
            display
            editable
            statusBanned
            dateCreated
            dateUpdated  
            assignedDriver
            type
        }
      }
  `,
    motorpoolBookingAvailable: `
    query motorpoolBookingAvailable(
        $id: String!, 
    ) {
        motorpoolBookingAvailable(
            id: $id
        ) {
            id
            patronId
            publicId
            itemId
            referenceNum
            itemName
            itemCode
            itemRate
            itemType
            statusInspection
            itemPhotoUrl
            name
            contact
            hasDeffective
            address
            email
            department
            purpose
            timeOfBooking
            startFormatted
            endFormatted
            start
            end
            status
            title
            duration
            total
            backgroundColor
            textColor
            display
            editable
            statusBanned
            dateCreated
            dateUpdated  
            assignedDriver
            type
        }
      }
  `,
    publicMotorpoolBookingList: `
    query publicMotorpoolBookingList($data: JSONString!) {
        publicMotorpoolBookingList(data: $data) {
            id
            patronId
            publicId
            itemId
            referenceNum
            itemName
            itemCode
            itemRate
            itemType
            statusInspection
            itemPhotoUrl
            name
            contact
            hasDeffective
            address
            email
            department
            purpose
            timeOfBooking
            startFormatted
            endFormatted
            start
            end
            status
            title
            duration
            total
            backgroundColor
            textColor
            display
            editable
            statusBanned
            dateCreated
            dateUpdated  
            assignedDriver
            type
        }
      }
  `,
    bookingFeedback: `
    query bookingFeedback(
        $id: String!, 
    ) {
        bookingFeedback(
            id: $id
        ) {
            id
            isSatisfied
            notes
            dateCreated
            dateUpdated  
        }
    }
  `,
    searchBookingPatron: `
    query searchBookingPatron($empId: String!, $email: String!) {
        searchBookingPatron(email: $email, empId: $empId) {
            departmentName
            id
            patronNameWId
            name
            fullAddress
            lat
            lng
            emergencyContact
            email
            departmentName
            blacklist {
                id
            }
            booking {
                status
            }
        }
    } 
  `,
    motorpoolVehiclesType: `
    query motorpoolVehiclesType {
        motorpoolVehiclesType {
          id,
          name,
          dateCreated,
          dateUpdated
        }
      }
  `,
    getCrmUser: `
        query getCrmUser($usersId: String!) {
        getCrmUser(usersId: $usersId) {
            id
    		bookingConfigFilter
        }
    }
    `,
}

export { mutation, query };