import axios from "axios";
import CONFIG from "app/config";
import swal from "sweetalert";
import { showMessage } from "app/store/fuse/messageSlice";
import randomString from "app/services/randomString";
import { mutation as mutationUpload, query as queryUpload } from "app/graphql/upload_graphql";
import uploadSvc from "app/services/upload";

export async function getFilePath(data, fileName, fileId = randomString(8)) {
    try {
        // Assuming uploadSvc returns a Promise, you can await it directly
        const result = await uploadSvc({
            file: data['file'],
            destination_file_name: fileName
        });

        if (result.status === 200 && result.data.status === "success") {
            var uploadData = result.data.uploadData;
            uploadData['module'] = 'motorpool';
            uploadData['file_id'] = fileId;

            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutationUpload.create,
                variables: {
                    data: JSON.stringify(uploadData),
                },
            });

            if (response.data.data.createUpload.status == "success") {
                var upload = response.data.data.createUpload.upload;
                return upload.path; // This will properly return from getFilePath function
            }
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
}

export async function uploadMultiple(images, module, path, featuredImageId, fileId = randomString(8)) {
    try {
        const returnData = {
            count: 0,
            hasNewFeatured: false,
            featuredImageId: '',
            featuredImagePath: '',
            fileId: fileId,
        };

        await Promise.all(images.map(async (image) => {

            console.log("TEST", image.id, featuredImageId, image);

            if (image.status == 'new') {

                const { file, fileName, id } = image;
                const destinationFileName = path + '-' + randomString() + (file ? file.name : fileName);

                const result = await uploadSvc({
                    file,
                    destination_file_name: destinationFileName
                });

                if (result.status === 200 && result.data.status === 'success') {
                    const uploadData = {
                        ...result.data.uploadData,
                        module,
                        file_id: fileId,
                        base64: image.url
                    };

                    const response = await axios.post(CONFIG.API + '/api/', {
                        query: mutationUpload.create,
                        variables: {
                            data: JSON.stringify(uploadData)
                        }
                    });

                    if (response.data.data.createUpload.status === 'success') {
                        if (featuredImageId === id) {
                            const upload = response.data.data.createUpload.upload;
                            returnData.hasNewFeatured = true;
                            returnData.featuredImageId = upload.id;
                            returnData.featuredImagePath = upload.path;
                        }
                        returnData.count += 1;
                    }
                }
            } else if (image.status === 'deleted') {

                const response = await axios.post(CONFIG.API + '/api/', {
                    query: mutationUpload.removeUploadById,
                    variables: {
                        id: image.id
                    }
                });

                if (response.data.data.removeUploadById.status === 'success') {
                    if (featuredImageId === image.id) {
                        returnData.hasNewFeatured = true;
                        returnData.featuredImageId = "";
                        returnData.featuredImagePath = "";
                    }
                }


            } else {
                if (featuredImageId === image.id) {
                    returnData.hasNewFeatured = true;
                    returnData.featuredImageId = image.id;
                    returnData.featuredImagePath = image.path;
                }
                returnData.count += 1;
            }

        }));

        return returnData;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
}