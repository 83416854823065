import CONFIG from "app/config";
import axios from "axios";

const uploadSvc = (data) => {

  // if (!data.file) {
  //   return {
  //     status: "error",
  //     data: {
  //       status: "error",
  //       msg: "file not found"
  //     }
  //   };
  // }

  const formData = new FormData();
    
  formData.append('file', data['file']);

  formData.append('destination_file_name', data['destination_file_name']);

  const uploadFile = async () => {
    let attempts = 0;
    const maxAttempts = 3;
    while (attempts < maxAttempts) {
      try {
        const response = await axios.post(CONFIG.API + "/external/uploads/gcloud", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        return response;
      } catch (error) {
        attempts++;
        console.error(`Upload failed, attempt ${attempts} of ${maxAttempts}`, error);
        if (attempts >= maxAttempts) {
          return {
            status: "error",
            data: {
              status: "error",
              msg: "Upload failed after multiple attempts"
            }
          };
        }
      }
    }
  };

  return uploadFile();
};


export {uploadSvc};