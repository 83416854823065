import { lazy } from 'react';
import { authRoles } from 'app/auth';

const ItemDepartment = lazy(() => import('./inventory/item-department/ItemDepartment'));
const ItemPublisher = lazy(() => import('./inventory/item-publisher/ItemPublisher'));
const ItemCourse = lazy(() => import('./inventory/item-course/ItemCourse'));
const ItemType = lazy(() => import('./inventory/item-type/ItemType'));
const ItemCategory = lazy(() => import('./inventory/item-category/ItemCategory'));
const PatronType = lazy(() => import('./patron/type/PatronType'));
const BookingItemType = lazy(() => import('./booking/booking-item-type/BookingItemType'));
const BookingDepartments = lazy(() => import('./booking/department/BookingDepartments'));
const BookingConfiguration = lazy(() => import('./booking/configuration/BookingConfiguration'));
const BookingAlerts = lazy(() => import('./booking/alerts/BookingAlerts'));
const GlobalNote = lazy(() => import('./bookstore/global-note/GlobalNote'));
const GlobalHeader = lazy(() => import('./bookstore/global-header/GlobalHeader'));
const VendorsGlobalNote = lazy(() => import('./vendors/global-note/VendorsGlobalNote'));
const VendorsGlobalHeader = lazy(() => import('./vendors/global-header/VendorsGlobalHeader'));
const BusCategory = lazy(() => import('./bus/category/BusCategory'));
const AuditLogs = lazy(() => import('./audit-logs/AuditLogs'));
const Amenities = lazy(() => import('./residence-hall/amenities/Amenities'));
const RHConfiguration = lazy(() => import('./residence-hall/configuration/RHConfiguration'));
const VisitAgreement = lazy(() => import('./residence-hall/visit_agreement/VisitAgreement'));
const InventoryType = lazy(() => import('./motorpool/inventory-type/InventoryType'));
const InventoryCategory = lazy(() => import('./motorpool/inventory-category/InventoryCategory'));
const VehiclesType = lazy(() => import('./motorpool/vehicles-type/vehiclesType'));
const CheckpointGroup = lazy(() => import('./patrol/checkpoint_group/CheckpointGroup'));
const VehicleMechanic = lazy(() => import('./motorpool/vehicle-mechanics/vehicleMechanic'));
const VehiclesTracker = lazy(() => import('./motorpool/vehicles-tracker/vehiclesTracker'));
const BusMechanic = lazy(() => import('./bus-management/bus-mechanics/busMachanic'));
const BusTracker = lazy(() => import('./bus-management/bus-tracker/busTracker'));
const StoreAlerts = lazy(() => import('./alerts/store-alerts/StoreAlerts'));

const SettingsAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/settings/inventory/item-department',
            element: <ItemDepartment />,
            auth: ["set-inv-dept"],
        },
        {
            path: 'apps/settings/inventory/item-type',
            element: <ItemType />,
            auth: ["set-inv-type"],
        },
        {
            path: 'apps/settings/inventory/item-category',
            element: <ItemCategory />,
            auth: ["set-inv-cat"],
        },
        {
            path: 'apps/settings/inventory/item-course',
            element: <ItemCourse />,
            auth: ["set-inv-crse"],
        },
        {
            path: 'apps/settings/inventory/item-publisher',
            element: <ItemPublisher />,
            auth: ["set-inv-pub"],
        },
        {
            path: 'apps/settings/patron/type',
            element: <PatronType />,
            auth: ["set-pat-type"],
        },
        {
            path: 'apps/settings/booking/booking-item-type',
            element: <BookingItemType />,
            auth: ["set-book-type"],
        },
        {
            path: 'apps/settings/booking/departments',
            element: <BookingDepartments />,
            auth: ["set-book-dept"],
        },
        {
            path: 'apps/settings/booking/configuration',
            element: <BookingConfiguration />,
            auth: ["set-book-conf"],
        },
        {
            path: 'apps/settings/booking/alerts',
            element: <BookingAlerts />,
            auth: ["set-book-conf"],
        },
        {
            path: 'apps/settings/store/global-note',
            element: <GlobalNote />,
            auth: ["set-store-note"],
        },
        {
            path: 'apps/settings/store/global-header',
            element: <GlobalHeader />,
            auth: ["set-store-head"],
        },
        {
            path: 'apps/settings/vendors/global-note',
            element: <VendorsGlobalNote />,
            auth: ["set-vendors-note"],
        },
        {
            path: 'apps/settings/vendors/global-header',
            element: <VendorsGlobalHeader />,
            auth: ["set-vendors-head"],
        },
        {
            path: 'apps/settings/bus/category',
            element: <BusCategory />,
            auth: ["set-bus-cat"],
        },
        {
            path: 'apps/settings/audit-logs',
            element: <AuditLogs />,
            auth: ["set-aud-logs"],
        },
        {
            path: 'apps/settings/residence-hall/amenities',
            element: <Amenities />,
            auth: ["set-rh-amn"],
        },
        {
            path: 'apps/settings/residence-hall/configuration',
            element: <RHConfiguration />,
            auth: ["set-rh-config"],
        },
        {
            path: 'apps/settings/residence-hall/agreement/:type',
            element: <VisitAgreement />,
            auth: ["set-rh-checkout", "set-rh-visit"],
        },
        {
            path: 'apps/settings/motorpool/inventory-type',
            element: <InventoryType />,
            auth: ["set-mot-inv-type"],
        },
        {
            path: 'apps/settings/motorpool/inventory-category',
            element: <InventoryCategory />,
            auth: ["set-mot-inv-cat"],
        },
        {
            path: 'apps/settings/motorpool/vehicles-type',
            element: <VehiclesType />,
            auth: ["set-mot-vcs-type"],
        },
        {
            path: 'apps/settings/patrol/checkpoint-group',
            element: <CheckpointGroup />,
            auth: ["set-ptrl-chkgrp"],
        },
        {
            path: 'apps/settings/motorpool/vehicles-tracker',
            element: <VehiclesTracker />,
            auth: ["set-mot-trcd"],
        },
        {
            path: 'apps/settings/motorpool/vehicles-mechanic',
            element: <VehicleMechanic />,
            auth: ["set-mot-vcs-mec"],
        },
        {
            path: 'apps/settings/bus-management/mechanic',
            element: <BusMechanic />,
            auth: ["set-bus-mec"],
        },
        {
            path: 'apps/settings/bus-management/tracker',
            element: <BusTracker />,
            auth: ["set-bus-trc"],
        },
        {
            path: 'apps/settings/store/alerts',
            element: <StoreAlerts />,
            auth: ["set-bus-cat"],
        }
    ],
};

export default SettingsAppConfig;
