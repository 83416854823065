import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { styled, lighten } from '@mui/material/styles';
import { showMessage } from "app/store/fuse/messageSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Checkbox, FormControl, FormHelperText, Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TakeUploadService from "app/services/takeUploadService/takeUploadService";
import randomString from "app/services/randomString";
import { getFilePath } from "app/services/uploadService";
import { createUpdateDriver } from "app/main/apps/motorpool/store/driverManagementSlice";

const Root = styled('div')(({ theme }) => ({
    // background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
    //   theme.palette.primary.dark,
    //   0.5
    // )} 100%)`,
    background: theme.palette.primary,
    color: theme.palette.primary.contrastText,

    '& .bg-icon': {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${lighten(
            theme.palette.primary.dark,
            0.1
        )} 100%)`,
        color: theme.palette.primary.contrastText,
    },

}));
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    licenseNumber: yup.string().required("License Number is required"),
    licenseExpiration: yup.string().required("License Expiration is required"),
});


const defaultValues = {
    id: "",
    name: "",
    licenseNumber: "",
    licenseExpiration: "",
    frontLicenseIdUrl: "",
    frontLicenseIdPath: "",
    backLicenseIdUrl: "",
    backLicenseIdPath: "",
    isPatron: false,
};




function DriverManagementNewModal(props) {
    const { module, data, loadData, prefillData, isCrm = false } = props;
    const dispatch = useDispatch();

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState,
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        trigger
    } = methods;

    const wData = watch();

    const [openDialog, setOpenDialog] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const { isValid, dirtyFields, errors } = formState;

    useEffect(() => {
        if (openDialog && module === 'edit') {
            setValue("id", data.id);
            setValue("name", data.name);
            setValue("licenseNumber", data.licenseNumber);
            setValue("licenseExpiration", data.licenseExpiration);
            setValue("frontLicenseIdUrl", data.frontLicenseIdUrl);
            setValue("frontLicenseIdPath", data.frontLicenseIdPath);
            setValue("backLicenseIdUrl", data.backLicenseIdUrl);
            setValue("backLicenseIdPath", data.backLicenseIdPath);
            setValue("isPatron", data.isPatron);
        }
    }, [openDialog, module]);

    function handleOpenDialog() {
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        reset(defaultValues);
        setOpenDialog(false);
    }

    function setUrl(model, file) {
        if (model == 'backLicenseIdUrl' && file == "") {
            setValue("backLicenseIdPath", "");
        }
        if (model == 'frontLicenseIdUrl' && file == "") {
            setValue("frontLicenseIdPath", "");
        }
        setValue(model, file);
    }

    async function handleSave() {

        setIsSaving(true);

        var fData = getValues();

        if (typeof fData.frontLicenseIdUrl === 'object') {
            fData.frontLicenseIdPath = await getFilePath(fData.frontLicenseIdUrl, 'motorpool/booking/driver-' + randomString() + (fData.frontLicenseIdUrl.file ? fData.frontLicenseIdUrl.file.name : fData.frontLicenseIdUrl.fileName));
        }
        if (typeof fData.backLicenseIdUrl === 'object') {
            fData.backLicenseIdPath = await getFilePath(fData.backLicenseIdUrl, 'motorpool/booking/driver-' + randomString() + (fData.backLicenseIdUrl.file ? fData.backLicenseIdUrl.file.name : fData.backLicenseIdUrl.fileName));
        }

        if (fData.frontLicenseIdPath == "error" || fData.backLicenseIdPath == "error") {
            return dispatch(
                showMessage({
                    message: "Something went wrong. Please try again later. ",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }

        const result = await trigger(["name", "licenseNumber", "licenseExpiration"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            setIsSaving(false);
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        } else {
            if (Object.keys(dirtyFields).length === 0) {
                setIsSaving(false);
                dispatch(
                    showMessage({
                        message: "No changes have been made.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "warning",
                    })
                );

            } else {
                setIsSaving(true);
                fData.isCrm = isCrm;
                dispatch(createUpdateDriver(fData)).then(function () {
                    setIsSaving(false);
                    setOpenDialog(false);
                    if (module == 'item') {
                        prefillData(fData.licenseNumber)
                    } else {
                        loadData();
                    }
                    reset(defaultValues);

                });
            }

        }
    }

    return (
        <Root>
            {module == "item" && (
                <Button
                    className="whitespace-nowrap mx-10 rounded-md text-white"
                    variant="contained"
                    color="primary"
                    onClick={handleOpenDialog}
                >
                    <span className="hidden sm:flex">Create Driver Record</span>
                    <span className="flex sm:hidden">Create Driver Record</span>
                </Button>
            )}
            {module == "new" && (
                <Button
                    className="whitespace-nowrap mx-10 rounded-md text-white"
                    variant="contained"
                    color="primary"
                    onClick={handleOpenDialog}
                >
                    <span className="hidden sm:flex">Add Driver</span>
                    <span className="flex sm:hidden">Add Driver</span>
                </Button>
            )}
            {module == "edit" && (
                <Tooltip title={"Edit Driver"} arrow>
                    <IconButton
                        onClick={handleOpenDialog}
                        size="large"
                    >
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
            )}

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
                scroll="body"
                sx={{

                    '& .MuiPaper-root': {
                        width: "100%",
                    },
                }}
            >
                <AppBar position="static" elevation={0}>
                    <Toolbar className="flex w-full">
                        <Typography variant="subtitle1" color="inherit">
                            {module === "item" ? 'Create Driver Record' : (module === "edit" ? 'Edit Driver' : 'Add Driver ')}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <form
                    noValidate
                    className="flex flex-col"
                >
                    <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
                        <div className="flex items-center justify-between">
                            <FormControl
                                className="flex w-full mx-16 ml-0"
                                variant="outlined">
                                <FormControlLabel
                                    control={
                                        <Controller
                                            name="isPatron"
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    }
                                    label="Patron is the Driver"
                                />
                            </FormControl>
                        </div>
                        <Typography className="text-15 font-medium" color="text.primary">Driver Name on the Card <span className="text-red-500">*</span></Typography>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className=" mb-16"
                                    // label="Type"
                                    id="name"
                                    variant="outlined"
                                    error={!!errors.name}
                                    helperText={errors?.name?.message}
                                    required
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: "40px"
                                        }
                                    }}
                                />
                            )}
                        />
                        <Stack gap={1} direction={'row'}>
                            <FormControl className="flex-1">
                                <Typography className=" text-15 font-medium" color="text.primary">Drivers License # <span className="text-red-500">*</span></Typography>
                                <Controller
                                    name="licenseNumber"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className=" mb-16"
                                            // label="Type"
                                            id="licenseNumber"
                                            variant="outlined"
                                            error={!!errors.licenseNumber}
                                            helperText={errors?.licenseNumber?.message}
                                            required
                                            fullWidth
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    height: "40px"
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                            <FormControl>
                                <Typography className="text-15 font-medium" color="text.primary">Expiration <span className="text-red-500">*</span></Typography>
                                <Controller
                                    name="licenseExpiration"
                                    control={control}
                                    render={({ field }) => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                value={field.value}
                                                onChange={(date) => {
                                                    field.onChange(!!date ? date : '')
                                                }}
                                                required
                                                error={!!errors.licenseExpiration
                                                }
                                                helperText={errors?.licenseExpiration?.message}
                                                minDate={new Date()}
                                                renderInput={(params) => <TextField  {...params}
                                                    required
                                                    error={!!errors.licenseExpiration} sx={{
                                                        // width: { sm: 200, md: 400 },
                                                        "& .MuiInputBase-root": {
                                                            height: 40
                                                        },
                                                        "& .MuiOutlinedInput-root": {
                                                            pr: 2, py: 0
                                                        }
                                                    }} />}

                                            />
                                            <FormHelperText error={!!errors.licenseExpiration}>{errors?.licenseExpiration?.message}</FormHelperText>
                                        </LocalizationProvider>
                                    )}
                                />
                            </FormControl>
                        </Stack>

                        <Stack direction={'row'} gap={4}>
                            <Stack className=" w-1/2">

                                <Typography className="text-15 font-medium" color="text.primary">Driver's License  <span className="text-red-500"></span></Typography>
                                <TakeUploadService model={"frontLicenseIdUrl"} url={wData.frontLicenseIdUrl} setUrl={setUrl} />
                            </Stack>
                            <Stack className=" w-1/2">
                                <Typography className="text-15 font-medium" color="text.primary">Government License  <span className="text-red-500"></span></Typography>
                                <TakeUploadService model={"backLicenseIdUrl"} url={wData.backLicenseIdUrl} setUrl={setUrl} />
                            </Stack>
                        </Stack>



                    </DialogContent>
                    <DialogActions className="justify-center px-8 py-16">
                        <div className="px-16 mt-8">
                            <LoadingButton
                                className="rounded-md mr-5"
                                variant="contained"
                                color="primary"
                                type="button"
                                disabled={isSaving}
                                onClick={handleSave}
                            >
                                Save
                            </LoadingButton>
                            <Button
                                className="rounded-md ml-5"
                                variant="outlined"
                                type="button"
                                disabled={isSaving}
                                onClick={handleCloseDialog}
                            >
                                Cancel
                            </Button>
                        </div>
                    </DialogActions>
                </form>
            </Dialog>
        </Root>
    );
}

export default DriverManagementNewModal;
