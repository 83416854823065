import * as React from "react";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";

import FusePageCarded from "@fuse/core/FusePageCarded";
import _ from "@lodash";
import { useParams, useLocation } from "react-router-dom";
import { Divider, Stack, Typography } from "@mui/material";
import CONFIG from "app/config";
import { checkFuelAuthById } from "app/main/apps/bus-management/store/busFleetSlice";

const Root = styled(FusePageCarded)(({ theme }) => ({
    "& .FusePageCarded-header": {
        minHeight: 72,
        height: 72,
        alignPatrons: "center",
        [theme.breakpoints.up("sm")]: {
            minHeight: 136,
            height: 136,
        },
    },
    "& .MuiStepLabel-label, .MuiStepLabel-iconContainer": {
        fontSize: 16,
    },
    "& .FusePageCarded-topBg": {
        background: "unset",
    },
    "& .FusePageCarded-contentCard": {
        backgroundColor: "unset",
        boxShadow: "unset",
    },
    "& .FusePageCarded-content": {
        overscrollBehavior: "unset",
    },
}));

function AuthorizationCheck() {
    const routeParams = useParams();
    const dispatch = useDispatch();

    const [data, setData] = React.useState({});

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");

    console.log("AuthorizationCheck", id);

    React.useEffect(() => {
        dispatch(checkFuelAuthById(id)).then((action) => {
            console.log("AuthorizationCheck", action.payload);
            setData(action.payload);
        });
    }, [dispatch]);

    return (
        <Root
            content={
                <div className="p-16 max-720px:p-4 sm:p-24 max-w-screen-md m-auto mb-56 mt-24 md:mt-9 bg-white shadow-md rounded-lg">
                    {!!data ? (
                        <div className="p-16 max-720px:p-4">
                            <Stack
                                direction={"row"}
                                gap={0.2}
                                flexWrap={"wrap"}
                            >
                                <div className=" max-720px:w-full ">
                                    <img
                                        className=" min-w-192 w-full box-content "
                                        alt="user photo"
                                        src="assets/images/logos/cmi-logo-blue.png" // Replace with the actual image source
                                    />
                                    <Stack className="text flex-1 max-720px:block hidden">
                                        <Typography className=" text-grey-600 whitespace-nowrap print:text-9 text-center">
                                            Financial Affairs & Business
                                            Services
                                        </Typography>
                                        <Typography className=" text-grey-600 whitespace-nowrap print:text-9 text-center">
                                            College of the Marshall Islands
                                        </Typography>
                                        <Typography className=" text-grey-600 whitespace-nowrap print:text-9 text-center">
                                            P.O. Box 1258 Majuro, MH 96960
                                        </Typography>
                                        <Typography className=" text-grey-600 whitespace-nowrap print:text-9 text-center">
                                            TEL: (692) 625-4931 • FAX: (692)
                                            625-7203
                                        </Typography>
                                    </Stack>

                                    <div className=" max-720px:mt-10 ">
                                        <Typography className="font-bold text-12px text-red-900 ">
                                            No.: {data.authorizationNumber}
                                        </Typography>
                                        <Typography className=" font-bold text-12px ">
                                            P.O. REFERENCE: {data.authorization}
                                        </Typography>
                                    </div>
                                </div>
                                <Stack className="text flex-1 max-720px:hidden mt-10">
                                    <Typography className=" text-grey-600 whitespace-nowrap print:text-9">
                                        Financial Affairs & Business Services
                                    </Typography>
                                    <Typography className=" text-grey-600 whitespace-nowrap print:text-9">
                                        College of the Marshall Islands
                                    </Typography>
                                    <Typography className=" text-grey-600 whitespace-nowrap print:text-9">
                                        P.O. Box 1258 Majuro, MH 96960
                                    </Typography>
                                    <Typography className=" text-grey-600 whitespace-nowrap print:text-9">
                                        TEL: (692) 625-4931 • FAX: (692)
                                        625-7203
                                    </Typography>
                                </Stack>

                                <Stack className="rf-number max-720px:w-full">
                                    <div className="">
                                        <img
                                            className=" w-136 box-content"
                                            alt="user photo"
                                            src={`${CONFIG.API}/external/qrcode/?code=${CONFIG.WEB}/authorization/check?id=${data.id}`} // Dynamically set the image source
                                        />
                                    </div>

                                    {/* <Divider className="border-1 border-black " />
                                            <div className=" p-10">

                                            </div> */}
                                </Stack>
                            </Stack>
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                gap={1}
                                justifyContent={"space-between"}
                                className="mt-16 "
                                flexWrap={"wrap"}
                            >
                                <div className="">
                                    <Typography className="text-14">
                                        To:{" "}
                                        <span className=" font-bold text-18">
                                            {data.station}
                                        </span>
                                    </Typography>
                                </div>

                                <div className="">
                                    <div className=" flex text-14">
                                        Date:{" "}
                                        <div className=" ml-4 font-bold text-primary-color border-b-1 border-black print:min-w-0 min-w-160">
                                            {data.dateFormatted}
                                        </div>
                                    </div>
                                </div>
                            </Stack>

                            <div className=" bg-blue-100 mt-16">
                                <Typography className="print:text-18 text-36 text-center font-extrabold max-720p:text-18">
                                    FUEL CHARGE AUTHORIZATION
                                </Typography>
                            </div>
                            <Stack gap={1} className="mt-16">
                                <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    gap={1}
                                >
                                    <div className="flex text-14 whitespace-nowrap w-full">
                                        Please provide:{" "}
                                        <div className="ml-4 border-b-1 border-black print:min-w-0 w-full max-w-160 font-bold">
                                            {!!data.other
                                                ? data.other
                                                : data.gallons == "full tank"
                                                ? "Full Tank"
                                                : data.gallons + " Gallons"}
                                        </div>
                                    </div>
                                    <div className="flex text-14 whitespace-nowrap w-full">
                                        Fuel Type:{" "}
                                        <div className="ml-4 border-b-1 border-black print:min-w-0 w-full max-w-160 font-bold">
                                            {data.fuelType}
                                        </div>
                                    </div>
                                </Stack>
                                <div className="flex text-14 whitespace-nowrap">
                                    For Vehicle Plate & Info:{" "}
                                    <div className=" flex-1 ml-4 border-b-1 border-black print:min-w-0 min-w-160 font-bold !whitespace-normal">
                                        {" "}
                                        {data.plate} - {data.vehicle}{" "}
                                    </div>
                                </div>
                                <div className="flex text-14 whitespace-normal">
                                    Vehicle Type:{" "}
                                    <div className="!whitespace-normal flex-1 ml-4 border-b-1 border-black print:min-w-0 min-w-160 font-bold">
                                        {" "}
                                        {data.vehicleType}{" "}
                                    </div>
                                </div>
                                <div className="flex text-14">
                                    Purpose:{" "}
                                    <div className=" flex-1 ml-4 border-b-1 border-black print:min-w-0 min-w-160 font-bold">
                                        {" "}
                                        {data.purpose}{" "}
                                    </div>
                                </div>
                                <Typography className=" text-14 print:max-w-max max-w-md">
                                    I,{" "}
                                    <span className=" inline-block flex-1 mx-6 border-b-1 border-black print:min-w-0 min-w-136 font-bold">
                                        {data.name}
                                        {/* ({data.department})  */}
                                    </span>
                                    acknowledge that I have read and understand
                                    the terms and conditions of this Fuel Usage
                                    Agreement. (As stated in the online Fuel
                                    Usage Agreement form.)
                                </Typography>
                            </Stack>
                            <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                className="mt-16"
                            >
                                <Stack gap={1}>
                                    {/* <Typography className=" text-14">Department:
                                        <span className=" inline-block flex-1 mx-6 border-b-1 border-black print:min-w-0 min-w-136 font-bold"> {data.authorizedDepartment}</span>
                                    </Typography> */}
                                    <Typography className=" text-14 ">
                                        Authorized Signature:
                                        <span className="!font-hesterica  text-center text-24 inline-block flex-1 mx-6 border-b-1 border-black print:min-w-0 min-w-136 font-bold">
                                            {data.authorizedName}
                                            {/* ({data.authorizedPosition}) */}
                                        </span>
                                    </Typography>
                                </Stack>
                                <Stack className="rf-number border-2 border-black justify-between items-center p-8">
                                    <Typography className=" text-14 font-bold text-center">
                                        Mileage: {data.mileage}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </div>
                    ) : (
                        <div className=" bg-blue-100 mt-16">
                            <Typography className="print:text-18 text-36 text-center font-extrabold ">
                                INVALID AUTHORIZATION
                            </Typography>
                        </div>
                    )}
                </div>
            }
        />
    );
}

export default AuthorizationCheck;
