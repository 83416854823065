import { lazy } from "react";
import { authRoles } from 'app/auth';

const BusFleet = lazy(() => import("./bus-fleet/BusFleet"));
const BmTerminals = lazy(() => import("./bm-terminals/BmTerminals"));
const BmRoutes = lazy(() => import("./bm-routes/BmRoutes"));
const BusFuelManagementRecord = lazy(() => import("./bm-fuel-management-record/BusFuelManagementRecord"));
const BmBusMaintenanceRecord = lazy(() => import("./bm-maintenance-record/BmBusMaintenanceRecord"));
const BmBusMaintenanceMonitoring = lazy(() => import("./bm-maintenance-monitoring/BmBusMaintenanceMonitoring"));
const BmBusIncidentReport = lazy(() => import("./bm-incident-report/BmBusIncidentReport"));
const BmLiveMonitoring = lazy(() => import("./bm-live-monitoring/BmLiveMonitoring"));
const BmBusTrips = lazy(() => import("./bm-bus-trips/BmBusTrips"));
const BmBusReport = lazy(() => import("./bm-bus-report/BmBusReport"));
const BmBusFuelReport = lazy(() => import("./bm-bus-report/fuel-report/BmBusFuelReport"));

const BusManagementAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: "apps/bus-management/bus-fleet",
            element: <BusFleet />,
            auth: ["bus-mng-fleet"],
        },
        {
            path: "apps/bus-management/terminals",
            element: <BmTerminals />,
            auth: ["bus-mng-tlms"],
        },
        {
            path: "apps/bus-management/routes",
            element: <BmRoutes />,
            auth: ["bus-mng-routes"],
        },
        {
            path: "apps/bus-management/fuel-management-record",
            element: <BusFuelManagementRecord />,
            auth: ["bus-mng-mtnc-fuel"],
        },
        {
            path: "apps/bus-management/maintenance-record",
            element: <BmBusMaintenanceRecord />,
            auth: ["bus-mng-mtnc-rec"],
        },
        {
            path: "apps/bus-management/maintenance-monitoring",
            element: <BmBusMaintenanceMonitoring />,
            auth: ["bus-mng-mtnc-mtrg"],
        },
        {
            path: "apps/bus-management/incident-report",
            element: <BmBusIncidentReport />,
            auth: ["bus-mng-inct-rep"],
        },
        {
            path: "apps/bus-management/bus-monitoring/live",
            element: <BmLiveMonitoring />,
            auth: ["bus-mng-live"],
        },
        {
            path: "apps/bus-management/bus-monitoring/trips",
            element: <BmBusTrips />,
            auth: ["bus-mng-trips"],
        },
        {
            path: "apps/bus-management/bus-report",
            element: <BmBusReport />,
            auth: ["bus-mng-rep"],
        },
        {
            path: "apps/bus-management/fuel-report",
            element: <BmBusFuelReport />,
            auth: ["bus-mng-rep"],
        },
    ],
};

export default BusManagementAppConfig;
