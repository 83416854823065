const mutation = {
    create: `
        mutation createBooking($data: JSONString!) {
            createBooking (data: $data) {
                status,
                res{
                    referenceNum
                    dateCreated
                },
                message
            }
        }
    `,
    update: `
        mutation updateBooking($data: JSONString!) {
            updateBooking (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    delete: `
        mutation deleteBooking($id: String!) {
            deleteBooking (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
    saveInspection: `
        mutation saveInspection($data: JSONString!) {
            saveInspection (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    resendBooking: `
        mutation resendBooking($data: JSONString!) {
            resendBooking (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    cancelBooking: `
        mutation cancelBooking($data: JSONString!) {
            cancelBooking (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    bookingvailable: `
    query bookingvailable(
        $id: String!, 
    ) {
        bookingvailable(
            id: $id
        ) {
            id
            patronId
            publicId
            itemId
            referenceNum
            itemName
            itemCode
            itemRate
            itemType
            statusInspection
            itemPhotoUrl
            name
            contact
            hasDeffective
            address
            email
            department
            purpose
            timeOfBooking
            startFormatted
            endFormatted
            start
            end
            status
            title
            duration
            total
            backgroundColor
            textColor
            display
            editable
            statusBanned
            dateCreated
            dateUpdated  
            assignedDriver
            type
        }
      }
  `,
    bookingAvailable: `
    query bookingAvailable(
        $id: String!, 
    ) {
        bookingAvailable(
            id: $id
        ) {
            id
            patronId
            publicId
            itemId
            referenceNum
            itemName
            itemCode
            itemRate
            itemType
            statusInspection
            itemPhotoUrl
            name
            contact
            hasDeffective
            address
            email
            department
            purpose
            timeOfBooking
            startFormatted
            endFormatted
            start
            end
            status
            title
            duration
            total
            backgroundColor
            textColor
            display
            editable
            statusBanned
            dateCreated
            dateUpdated  
            assignedDriver
            type
        }
      }
  `,
    getEventsBooking: `
    query getEventsBooking($fromDate: String!, $toDate: String!) {
        getEventsBooking(fromDate: $fromDate, toDate: $toDate){
            id
            patronId
            publicId
            departmentId
            itemId
            referenceNum
            itemName
            itemCode
            itemRate
            itemType
            itemPhotoUrl
            inspectionRequired
            statusInspection
            hasDeffective
            dateFormatted
            name
            contact
            address
            email
            department
            purpose
            timeOfBooking
            startFormatted
            endFormatted
            start
            end
            status
            title
            duration
            durationFormat
            total
            backgroundColor
            textColor
            display
            editable
            statusBanned
            dateCreated
            dateUpdated  
            type
            approvee {
                id
                name
                email
                position
                department
                level
                status
                bookingTypes
                dateCreated
                dateUpdated
            }
            approvedById
            invoice{
                invoiceNum
                subTotal
                grandTotal
                discount
                invoiceStatus
            }
        }
    }
    `,
    getEventsBookingPaginate: `
    query getEventsBookingPaginate(
            $limit: Int, 
            $keyword: String,  
            $page: Int, 
            $fromDate: String, 
            $toDate: String, 
            $department: String, 
            $type: String, 
            $status: String, 
            $order: String
        ) {
        getEventsBookingPaginate(
            limit: $limit, 
            keyword: $keyword,  
            page: $page, 
            fromDate: $fromDate, 
            toDate: $toDate, 
            department: $department, 
            type: $type, 
            status: $status, 
            order: $order
        ){
            page
            pages
            hasNext
            hasPrev
            totalItems
            itemsPerPage
            objects {  
                id
                patronId
                publicId
                departmentId
                itemId
                referenceNum
                itemName
                itemCode
                itemRate
                itemType
                itemPhotoUrl
                inspectionRequired
                statusInspection
                hasDeffective
                dateFormatted
                name
                contact
                address
                email
                department
                purpose
                timeOfBooking
                startFormatted
                endFormatted
                start
                end
                status
                title
                duration
                durationFormat
                total
                backgroundColor
                textColor
                display
                editable
                statusBanned
                dateCreated
                dateUpdated  
                type
                approvee {
                    id
                    name
                    email
                    position
                    department
                    level
                    status
                    bookingTypes
                    dateCreated
                    dateUpdated
                }
                approvedById
                invoice{
                    invoiceNum
                    subTotal
                    grandTotal
                    discount
                    invoiceStatus
                }
                assignedDriver
            }
        }
    }
  `,
    getEventsBookingCrm: `
        query getEventsBookingCrm($customerId: String) {
            getEventsBookingCrm(customerId: $customerId){
                id
                patronId
                publicId
                departmentId
                itemId
                referenceNum
                itemName
                itemCode
                itemRate
                itemType
                itemPhotoUrl
                inspectionRequired
                statusInspection
                hasDeffective
                dateFormatted
                name
                contact
                address
                email
                department
                purpose
                timeOfBooking
                startFormatted
                endFormatted
                start
                end
                status
                title
                duration
                durationFormat
                total
                backgroundColor
                textColor
                display
                editable
                statusBanned
                dateCreated
                dateUpdated  
                type
                approvee {
                    id
                    name
                    email
                    position
                    department
                    level
                    status
                    bookingTypes
                    dateCreated
                    dateUpdated
                }
                approvedById
                invoice{
                    invoiceNum
                    subTotal
                    grandTotal
                    discount
                    invoiceStatus
                }
            }
        }
    `,
    getEventsMotorpoolBookingCrm: `
        query getEventsMotorpoolBookingCrm($customerId: String) {
            getEventsMotorpoolBookingCrm(customerId: $customerId){
                id
                patronId
                publicId
                departmentId
                itemId
                referenceNum
                itemName
                itemCode
                itemRate
                itemType
                itemPhotoUrl
                inspectionRequired
                statusInspection
                hasDeffective
                dateFormatted
                name
                contact
                address
                email
                department
                purpose
                timeOfBooking
                startFormatted
                endFormatted
                start
                end
                status
                title
                duration
                durationFormat
                total
                backgroundColor
                textColor
                display
                editable
                statusBanned
                dateCreated
                dateUpdated  
                type
                approvee {
                    id
                    name
                    email
                    position
                    department
                    level
                    status
                    bookingTypes
                    dateCreated
                    dateUpdated
                }
                approvedById
                invoice{
                    invoiceNum
                    subTotal
                    grandTotal
                    discount
                    invoiceStatus
                }
            }
        }
    `,
    getEventsBookingCalendar: `
        query getEventsBooking($fromDate: String!, $toDate: String!) {
            getEventsBooking(fromDate: $fromDate, toDate: $toDate){
                id
                patronId
                publicId
                departmentId
                itemId
                referenceNum
                itemName
                itemCode
                itemRate
                itemType
                itemPhotoUrl
                inspectionRequired
                statusInspection
                hasDeffective
                dateFormatted
                name
                contact
                address
                email
                department
                purpose
                timeOfBooking
                startFormatted
                endFormatted
                start
                end
                status
                title
                duration
                durationFormat
                total
                backgroundColor
                textColor
                display
                statusBanned
                dateCreated
                dateUpdated  
                approvee {
                    id
                    name
                    email
                    position
                    department
                    level
                    status
                    bookingTypes
                    dateCreated
                    dateUpdated
                }
                approvedById
                invoice{
                    invoiceNum
                    subTotal
                    grandTotal
                    discount
                    invoiceStatus
                }
            }
        }
    `,

    getBookingById: `
    query getBookingById(
        $id: String!, 
    ) {
        getBookingById(
            id: $id
        ) {
            id
            patronId
            publicId
            itemId
            referenceNum
            itemName
            itemCode
            itemRate
            itemType
            itemPhotoUrl
            name
            contact
            address
            email
            department
            inspectionRequired
            statusInspection
            hasDeffective
            purpose
            timeOfBooking
            startFormatted
            endFormatted
            start
            end
            status
            title
            duration
            total
            backgroundColor
            textColor
            display
            editable
            statusBanned
            statusBooking
            assignedDriver
            dateCreated
            dateUpdated  
        }
    }
    `,

    getBookingInspectionById: `
    query getBookingInspectionById($bookingId: String!, $inspectionType: String!) {
        getBookingInspectionById(bookingId: $bookingId, inspectionType: $inspectionType) {
            id
            bookingId
            external1
            external2
            external3
            vehicle1
            vehicle2
            vehicle3
            vehicle4
            vehicle5
            vehicle6
            others1
            others2
            others3
            walls
            flooring
            windows
            doors
            electrical
            plumbing
            furniture
            equipment
            littering
            organize
            notes
            dateCreated
            dateUpdated
            images {
                id
                inspectionId
                uploadId
                fileName
                fileType
                fileId
                module
                path
                url
                status
                dateCreated
                dateUpdated
            }
        }
    }
    `,
}

export { mutation, query };