import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showMessage } from 'app/store/fuse/messageSlice';
import { setUserData } from 'app/auth/store/userSlice';
import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';
import { setInitialSettings } from 'app/store/fuse/settingsSlice';
import history from '@history';
import axios from "axios";
import CONFIG from "app/config";
import { mutation, query } from "app/main/ecommerce/graphql/login_graphql";

export const submitLogin =
    ({ email, password }) =>
        async (dispatch) => {
            return await EcommerceJwtService
                .signInWithEmailAndPassword(email, password)
                .then((user) => {

                    dispatch(setUserData(user));

                    return dispatch(loginSuccess());
                })
                .catch((errors) => {
                    errors.forEach((error) => {
                        return dispatch(
                            showMessage({
                                message: error.message,
                                autoHideDuration: 5000, //ms
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                },
                                variant: "warning",
                            })
                        );
                    });
                });
        };

export const logoutUser = () => async (dispatch, getState) => {

    history.push({
        pathname: '/e-commerce/home',
    });

    EcommerceJwtService.logout();

    return dispatch(setInitialSettings());
};

export const checkExistingPassword =
    (filter) => async (dispatch) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.checkExistingPassword,
            variables: filter
        });

        if (response.data.data.checkExistingPassword) {
            return false;
        } else {
            return true;
        }

    };

export const checkVerification =
    (filter) => async (dispatch) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.checkVerification,
            variables: filter
        });

        if (response.data.data.checkVerification) {
            return false;
        } else {
            return true;
        }

    };

export const forgotPassword = createAsyncThunk(
    "ecommerce/user/createEcommerceUsers",
    async (postdata, { dispatch, getState }) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.forgotPassword,
            variables: { data: JSON.stringify(postdata) },
        });


        return response.data.data.forgotPassword;
    }

);

export const activateAccount = createAsyncThunk(
    "ecommerce/user/activateAccount",
    async (userData, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.activateAccount,
            variables: {
                id: parseInt(userData.id),
            },
        });
        dispatch(
            showMessage({
                message: response.data.data.activateAccount["message"],
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                variant: response.data.data.activateAccount["status"],
            })
        );
    }
);

export const saveResetPassword = createAsyncThunk(
    "ecommerce/user/saveResetPassword",
    async (userData, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.saveResetPassword,
            variables: {
                data: JSON.stringify(userData),
                id: userData.id,
            },
        });
        return response.data.data.saveResetPassword;
    }
);

export const resetpassword = createAsyncThunk(
    "ecommerce/user/resetpassword",
    async (userData, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.resetpassword,
            variables: {
                data: JSON.stringify(userData),
                id: parseInt(userData.id),
            },
        });
        dispatch(
            showMessage({
                message: response.data.data.resetPassword["message"],
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                variant: response.data.data.resetPassword["status"],
            })
        );
    }
);

const initialState = {
    success: false,
    errors: [],
};

const loginSlice = createSlice({
    name: 'auth/login',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.success = true;
            state.errors = [];

            setTimeout(() => {
              window.location.reload(); 
            }, 100);
        },
        loginError: (state, action) => {
            state.success = false;
            state.errors = action.payload;
        },
    },
    extraReducers: {},
});

export const { loginSuccess, loginError } = loginSlice.actions;

export default loginSlice.reducer;
