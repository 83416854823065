/* DateUpdated: 03/20/23
* updatedBy: ico
* Summary: Redesign
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-49292-96
*/
import * as yup from 'yup';
import _ from '@lodash';
import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import PasswordStrengthBar from "react-password-strength-bar";

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';

import { showMessage } from "app/store/fuse/messageSlice";
import { emailVerified } from "app/main/crm/store/crmSlice";

const CardStyled = styled(Card)(() => ({
}));

const defaultValues = {
    password: "",
    passwordConfirm: ""
};

function VerifyEmailContent() {

    const dispatch = useDispatch();

    const routeParams = useParams();

    const { id } = routeParams;

    const [status, setStatus] = useState("");

    useEffect(() => {
        dispatch(emailVerified({ id: id })).then((action) => {
            console.log("action", action);
            setStatus(action.payload.status);
        });
    }, [id]);

    return (

        <CardStyled elevation={1} className="flex flex-col h-fit px-10 py-40">
            <DialogContent classes={{ root: 'py-25 px-25' }}>
                <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>

                    {status == "success" ?
                        <>

                            <Typography variant="h6" className="text-center">
                                <Icon className="text-green text-6xl">check_circle_outline</Icon>
                            </Typography>
                            <Typography variant="h6" className="font-medium text-18 sm:text-24 text-center">
                                Email Verification
                            </Typography>
                            <Typography className="w-full font-medium text-14 sm:text-14 text-center">
                                Your email has been successfully verified.
                            </Typography>
                        </>
                        : null}

                    {status == "error" ?
                        <>

                            <Typography variant="h6" className="text-center mt-16">
                                <Icon className="text-red text-6xl">cancel</Icon>
                            </Typography>
                            <Typography variant="h6" className="font-medium text-18 sm:text-24 text-center">
                                Email Verification
                            </Typography>
                            <Typography className="w-full font-medium text-14 sm:text-14 text-center">
                                Email Verification is not valid
                            </Typography>
                        </>
                        : null}
                </motion.div>
            </DialogContent>
        </CardStyled>

    );
}

export default VerifyEmailContent;
