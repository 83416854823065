import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useFormContext, Controller } from "react-hook-form";

import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import format from 'date-fns/format';
import { styled } from '@mui/material/styles';

import PrintFormConfirmation from "../print/PrintFormConfirmation"
const CardStyled = styled(Card)(({ theme }) => ({
    background: theme.palette.background.default
}));
function Tab6(props) {

    const { activeTab, handelClickPrint, printRef } = props;

    if (activeTab != 4) {
        return (<></>);
    }

    const methods = useFormContext();
    const dispatch = useDispatch();

    const { control, formState, setValue, trigger, watch } = methods;
    const { errors } = formState;

    const form = watch();

    return (
        <>
            <div className="w-full">
                <div className="p-9 pb-0 w-1/2 min-w-58rem max-600px:min-w-0 max-600px:w-full m-auto mb-48 mt-32 max-600px:mt-10 ">
                    <CardStyled className="flex flex-col shadow-0">
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                                <DialogContent classes={{ root: 'py-10 px-10 pb-0' }}>
                                    <div className=" text-right">
                                        <Button
                                            color="primary" className="rounded-0 py "
                                            onClick={handelClickPrint}
                                        >
                                            {/* <Icon className='mr-5' color="primary">print</Icon> Print */}
                                            Print
                                        </Button>
                                    </div>
                                    <div className='flex flex-row max-600px:flex-wrap'>
                                        <div className='flex flex-col relative mx-16 mb-24 w-full'>
                                            <div className='flex'>
                                                <ImageList sx={{ width: 170, height: 170 }} cols={1} className="overflow-hidden rounded-md">
                                                    <ImageListItem>
                                                        <img className="max-w-none w-auto h-full" src={form.itemPhotoUrl ? form.itemPhotoUrl : 'assets/images/ecommerce/product-image-placeholder.png'} alt={form.name} loading="lazy" />
                                                    </ImageListItem>
                                                </ImageList>
                                            </div>
                                            <Typography className='text-12 font-semibold mt-10'>
                                                {form.itemCode}
                                            </Typography>
                                            <Typography className='text-14 font-bold'>
                                                {form.itemName}
                                            </Typography>
                                            <div className="mt-10">
                                                <Typography className='text-14 font-bold'>
                                                    <span className="text-14 font-medium">CMI Rate :</span> ${form.itemRate} / (30 mins)
                                                </Typography>
                                                <Typography className='text-14 font-bold'>
                                                    <span className="text-14 font-medium">Total :</span> ${form.total}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className='flex flex-col mx-10 mb-24 w-full'>
                                            <Typography className=' text-14 font-semibold mt-10'>
                                                <span className=" text-14 font-medium">Date :</span> {form.tempStart ? format(form.tempStart, 'EEEE, MMMM dd, yyyy') : ""}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                <span className=" text-14 font-medium">Time :</span> {form.tempStart ? format(form.tempStart, 'hh:mm a') : ""} - {form.tempEnd ? format(form.tempEnd, 'hh:mm a') : ""}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                <span className=" text-14 font-medium">Duration : </span>{form.duration}
                                            </Typography>

                                            <Typography className=' text-14 font-semibold mt-10'>
                                                <span className=" text-14 font-medium">Name :</span> {form.name}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                <span className=" text-14 font-medium">Email :</span> {form.email}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                <span className=" text-14 font-medium">Contact :</span> {form.contact}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                <span className=" text-14 font-medium">Department :</span> {form.department}
                                            </Typography>
                                            <div>
                                                <Typography className='text-14 font-semibold  mt-5'>
                                                    Purpose/Instructions
                                                </Typography>
                                                <FormControl className="flex mx-16 mt-4 mb-9 ml-0" variant="outlined">
                                                    <Controller
                                                        name="purpose"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                id="purpose"
                                                                type="text"
                                                                error={!!errors.purpose}
                                                                helperText={errors?.purpose?.message}
                                                                required
                                                                multiline
                                                                rows={3}
                                                                variant="outlined"
                                                                sx={{
                                                                    "& .MuiOutlinedInput-root.MuiInputBase-root ": { padding: "8px 8px" }
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </DialogContent>
                            </Grid>
                        </Box>
                    </CardStyled>
                </div>
            </div>
            <PrintFormConfirmation printRef={printRef} form={form} />
        </>
    );
}

export default Tab6;
