import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { showMessage } from "app/store/fuse/messageSlice";

import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";

import { mutation as mutationUpload } from "app/graphql/upload_graphql";
import { query, mutation } from "app/graphql/booking/booking_graphql";
import { mutation as motorpoolMutation } from "app/main/apps/motorpool/grahpql/motorpool_booking_list_graphql";


export const getBookingInspectionById = createAsyncThunk(
    "bookingApp/bookings/getBookingInspectionById",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getBookingInspectionById,
            variables: {
                bookingId: params.id,
                inspectionType: params.inspectionType,
            },
        });

        if (response.data.data.getBookingInspectionById) {
            return response.data.data.getBookingInspectionById;
        }
    }
);


const saveImages = async (itemData) => {
    if (itemData.images.length) {
        var newImages = [];

        itemData.images.forEach((image, index) => {
            if (image.status === "new") {
                newImages.push({
                    index: index,
                    image: image
                });
            } else {
                if (itemData.featuredImageId == image.fileId) {
                    itemData.featuredImagePath = image.path;
                }
            }
        });

        if (newImages.length) {
            await Promise.all(newImages.map(async (image, index) => {
                const result = await uploadSvc({
                    file: image.image.file,
                    destination_file_name: "booking/item/item-" + randomString() + "-" + (image.image.file ? image.image.file.name : image.image.fileName)
                });

                if (result.status === 200 && result.data.status === "success") {
                    var uploadData = result.data.uploadData;
                    uploadData['module'] = 'booking-item';
                    uploadData['file_id'] = image.image.id;

                    const response = await axios.post(CONFIG.API + "/api/", {
                        query: mutationUpload.create,
                        variables: {
                            data: JSON.stringify(uploadData),
                        },
                    });

                    if (response.data.data.createUpload.status == "success") {
                        var upload = response.data.data.createUpload.upload;

                        if (itemData.featuredImageId == image.image.id) {
                            itemData.featuredImagePath = upload.path;
                        }
                        itemData.images[image.index].upload_id = upload.id;
                        itemData.images[image.index].file_name = upload.fileName;
                        itemData.images[image.index].file_id = upload.fileId;
                        itemData.images[image.index].file_type = upload.fileType;
                        itemData.images[image.index].path = upload.path;
                        itemData.images[image.index].module = upload.module;
                    }
                }
            }));
        }
    }
    return itemData;
};

export const saveInspection = createAsyncThunk(
    "bookingApp/bookings/saveInspection",
    async (itemData, { dispatch, getState }) => {

        console.log(itemData, 'aaaaaaa')

        await saveImages(itemData).then(async (itemData) => {

            itemData.has_deffective = Object.values(itemData).includes("Deffective");

            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.saveInspection,
                variables: {
                    data: JSON.stringify(itemData),
                    id: itemData.id,
                },
            });

            if (response.data.data.saveInspection.status != 'error') {
                dispatch(
                    showMessage({
                        message: "Inspection has been successfully saved." + (itemData.has_deffective ? " The Item has deffective." : ""),
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "success",
                    })
                );
            } else {
                dispatch(
                    showMessage({
                        message: "Something went wrong. Please try again later.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }

        });

    }
);

export const saveInspectionMotorpool = createAsyncThunk(
    "bookingApp/bookings/saveInspectionMotorpool",
    async (itemData, { dispatch, getState }) => {
        console.log(itemData, 'aaaaaaa')

        await saveImages(itemData).then(async (itemData) => {

            itemData.has_deffective = Object.values(itemData).includes("Deffective");
            itemData.date = new Date();

            const response = await axios.post(CONFIG.API + "/api/", {
                query: motorpoolMutation.saveInspectionMotorpool,
                variables: {
                    data: JSON.stringify(itemData),
                    id: itemData.id,
                },
            });

            if (response.data.data.saveInspectionMotorpool.status != 'error') {
                dispatch(
                    showMessage({
                        message: "Inspection has been successfully saved." + (itemData.has_deffective ? " The Item has deffective." : ""),
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "success",
                    })
                );
            } else {
                dispatch(
                    showMessage({
                        message: "Something went wrong. Please try again later.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }

        });

    }
);

const bookingsAdapter = createEntityAdapter({});

export const {
    selectAll: selectTypes,
    selectById: selectTypeById
} = bookingsAdapter.getSelectors((state) => state.bookingApp.bookings);

const bookingInspectionModalSlice = createSlice({
    name: 'bookingApp/bookingInspectionModal',
    initialState: bookingsAdapter.getInitialState({
        searchText: '',
        typeText: '',
    }),
    reducers: {},
    extraReducers: {
        [getBookingInspectionById.fulfilled]: (state, action) => action.payload,
        [saveInspection.fulfilled]: (state, action) => action.payload,
    },
});


export default bookingInspectionModalSlice.reducer;
