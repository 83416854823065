import {
	createSlice,
	createAsyncThunk,
	createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import {
	query,
	mutation,
} from "app/main/apps/residence-hall/graphql/houses_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";

export const getHouses = createAsyncThunk(
	"residenceHallApp/house-management/getHouses",
	async () => {
		const response = await axios.post(CONFIG.API + "/api/", {
			query: query.list,
		});
		var data = response.data.data.houseList;

		return data;
	}
);

export const removeHouses = createAsyncThunk(
	"residenceHallApp/house-management/removeHouses",
	async (houseIds, { dispatch, getState }) => {

		await houseIds.map(async (id) => {
			await axios.post(CONFIG.API + "/api/", {
				query: mutation.delete,
				variables: {
					id: id,
				},
			});
		});

		dispatch(
			showMessage({
				message: "Hall(s) has been successfully deleted.",
				autoHideDuration: 5000, //ms
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "right",
				},
				variant: "success",
			})
		);

		return houseIds;
	}
);

export const saveHouses = createAsyncThunk(
	"residenceHallApp/house-management/saveHouses",
	async (categoryData, { dispatch, getState }) => {
		if (categoryData.id) {
			const response = await axios.post(CONFIG.API + "/api/", {
				query: mutation.edit,
				variables: {
					data: JSON.stringify(categoryData),
				},
			});


			if (response.data.data.editHouses) {
				dispatch(
					showMessage({
						message: "Item Category has been successfully updated.",
						autoHideDuration: 5000, //ms
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "right",
						},
						variant: "success",
					})
				);
			} else {
				dispatch(
					showMessage({
						message: "Something went wrong. Please try again later.",
						autoHideDuration: 5000, //ms
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "right",
						},
						variant: "error",
					})
				);
			}
		} else {
			const response = await axios.post(CONFIG.API + "/api/", {
				query: mutation.create,
				variables: { data: JSON.stringify(categoryData) },
			});

			if (response.data.data.createHouses) {
				dispatch(
					showMessage({
						message: "Item Category has been successfully saved.",
						autoHideDuration: 5000, //ms
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "right",
						},
						variant: "success",
					})
				);
			} else {
				dispatch(
					showMessage({
						message: "Something went wrong. Please try again later.",
						autoHideDuration: 5000, //ms
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "right",
						},
						variant: "error",
					})
				);
			}
		}
	}
);

const houseAdapter = createEntityAdapter({});

export const {
	selectAll: selectHouses,
	selectById: selectHousesById,
} = houseAdapter.getSelectors((state) => state.residenceHallApp.houses);

const houseSlice = createSlice({
	name: "residenceHallApp/house-management",
	initialState: houseAdapter.getInitialState({
		searchText: "",
		showModal: false,
	}),
	reducers: {
		setHousesSearchText: {
			reducer: (state, action) => {
				state.searchText = action.payload;
			},
			prepare: (event) => ({ payload: event.target.value || "" }),
		},
	},
	extraReducers: {
		[getHouses.fulfilled]: houseAdapter.setAll,
		[removeHouses.fulfilled]: (state, action) =>
			houseAdapter.removeMany(state, action.payload),
		[saveHouses.fulfilled]: (state, action) => action.payload,
	},
});

export const { setHousesSearchText } = houseSlice.actions;

export default houseSlice.reducer;
