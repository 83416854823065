const mutation = {
    createUpdateBmBusFleet: `
        mutation createUpdateBmBusFleet($data: JSONString) {
           createUpdateBmBusFleet(data: $data) {
                status,
                res,
                message
            }
        }
    `,
    removeBmBusFleet: `
        mutation removeBmBusFleet($id: String) {
           removeBmBusFleet(id: $id) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    bmBusFleet: `
        query bmBusFleet($type: String){
            bmBusFleet(type: $type){
                id
                type
                name
                description
                featuredImagePath
                featuredImageId
                featuredImageUrl
                fileId
                status
                notes
                busType
                plate
                brand
                model
                color
                year
                transmission
                capacity
                orcr
                orcrUrl
                orcrRegistration
                orcrExpiration
                fuelConsumptionLiters
                fuelConsumptionKm
                fullTankLiters
                fuelType
                dateCreated
                title
                busNumber
                routeId
                route
                driverId
                lastTravel
            }
        }
    `,
    bmBusFleetMonitoring: `
        query bmBusFleet($type: String){
            bmBusFleet(type: $type){
                id
                name
                description
                featuredImagePath
                fileId
                status
                dateCreated
                title
                busNumber
                routeId
                driverId
                trackerId
                driverName
                route
            }
        }
    `,
    bmBusFleetPosition: `
        query bmBusFleet($type: String){
            bmBusFleet(type: $type){
                id
                name
                description
                featuredImagePath
                fileId
                status
                dateCreated
                title
                busNumber
                routeId
                driverId
                trackerId
                driverName
                route
                trackerPosition
            }
        }
    `,
    bmBusIncidentReports: `
        query getTicketsAdmin($busIncidentReport: Boolean, $status: String!) {
            getTicketsAdmin (busIncidentReport: $busIncidentReport, status: $status){
                id
                ticketNum
                topics
                userId
                userName
                topicsId
                topicsAssignee
                topicsAssignees
                subject
                message
                status
                statusView
                statusViewDate
                status
                busId
                bus
                dateCreated
                dateUpdated
            }
        }
    `,
    busDrivers: `
        query busDrivers {
            busDrivers {
                id
                userFullName
            }
        }
    `,
    getBusSession: `
        query getBusSession($busId: String, $sessionNumber: String) {
            getBusSession(busId: $busId, sessionNumber: $sessionNumber){
                id
                departTerminal
                arrivedTerminal
                status
                departDate
                arrivedDate
                time
            }
        }
    `,
    getBusTrips: `
        query getBusTrips{
            getBusTrips{
                id
                status
                routeName
                driverName
                busName
                departDate
                sessionNumber
                lastArrivedDate
                totalTrip
                trackerId
                latestIncident
                dateCreated
            }
        }
    `,
    getBusTrackerCoordinates: `
        query getBusTrackerCoordinates($timezone: String, $trackerId: String, $start: String, $end: String){
            getBusTrackerCoordinates(timezone: $timezone, trackerId: $trackerId, start: $start, end: $end){
                deviceId
                routes{
                    lat
                    lng
                }
            }
        }
    `,
    checkFuelAuthById: `
        query checkFuelAuthById($id: String!) {
            checkFuelAuthById(id: $id){
               id
                authorization
                authorizationNumber
                station
                other
                gallons
                dateCreated
                fuelType
                vehicle
                vehicleId
                vehicleType
                plate
                purpose
                name
                department
                authorizedDepartment
                authorizedName
                authorizedPosition
                mileage
                date
            }
        }
    `,
    bmBusReport: `
        query bmBusReport($type: String, $fromDate: String, $toDate: String){
            bmBusReport(type: $type, fromDate: $fromDate, toDate: $toDate){
            id
            date
            busNumber
            totalPassengers
            mostPassengers
            leastPassengers
            timeOfMostPassengers
            }
        }
    `,
};

export { mutation, query };
