import * as React from 'react';
import * as yup from "yup";
import { useDeepCompareEffect } from "@fuse/hooks";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { styled } from "@mui/material/styles";
import { showMessage } from "app/store/fuse/messageSlice";
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import FusePageSimple from "@fuse/core/FusePageSimple";
import Button from "@mui/material/Button";
import withReducer from "app/store/withReducer";
import _ from "@lodash";
import reducer from "app/main/apps/crm/store";

import CrmFooter from 'app/main/crm/footer/CrmFooter';

import CrmJwtService from 'app/services/crm/CrmJwtService';
import CrmHeaderPublic from '../../header/CrmHeaderPublic';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import MotorpoolBookingCalendarHeader from './content/MotorpoolBookingCalendarHeader';
import { publicBookingList, getItemTypes, getCrmUser } from "app/main/crm/store/motorpoolBookingSlice";
import CrmPublicBookingTabs from '../../CrmPublicBookingTabs';

const Root = styled(FusePageSimple)(() => ({
    "& .FusePageSimple-contentWrapper": {

    },
    "& .FusePageCarded-topBg": {
        background: "unset"
    },
    minHeight: "unset",
    "& .FusePageSimple-contentCard, .FusePageSimple-header": {
        backgroundColor: "unset",
        background: "unset",
        boxShadow: "unset"
    },
    "& .FusePageSimple-contentWrapper": {
        overscrollBehavior: "auto !important",
        maxWidth: "1366px",
        margin: "10px auto",
    },
    "& .ps__rail-y": {
        display: "none !important"
    }
}));


function MotorpoolBookingCalendar(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const routeParams = useParams();
    const user = CrmJwtService.getDecodedAccessToken();
    const calendarRef = useRef();
    const [bookings, setBookings] = useState([]);
    const [itemTypes, setItemTypes] = useState([]);
    const [currentDate, setCurrentDate] = useState();
    const [selectedType, setSelectedType] = useState("all");
    const [calendarView, setCalendarView] = useState("dayGridMonth");
    const [initialDate, setInitialDate] = useState(new Date());
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadedCalendar, setIsLoadedCalendar] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const calendarViewParams = queryParams.get('calendarView');
    const typeParams = queryParams.get('type');
    const fromDateParams = queryParams.get('fromDate');
    const toDateParams = queryParams.get('toDate');

    const calendarApi = () => calendarRef.current?.getApi();

    const handleDates = (rangeInfo) => {
        setCurrentDate(rangeInfo);
        loadData(rangeInfo);
    };

    useEffect(() => {
        if (calendarViewParams && typeParams && fromDateParams && toDateParams && !isLoaded) {

            console.log(new Date(fromDateParams.replace(' ', '+')), fromDateParams.replace(' ', '+'));

            setInitialDate(new Date(fromDateParams.replace(' ', '+')));
            setSelectedType(typeParams == 'null' ? 'all' : typeParams);
            setCalendarView(calendarViewParams);

            setIsLoaded(true);
        } else {
            setIsLoaded(true);
        }
        dispatch(getItemTypes()).then((action) => {
            if (action.payload) {
                console.log("getItemTypes", action.payload);
                setItemTypes(action.payload);
            }
        });
    }, [dispatch]);

    const loadData = (rangeInfo) => {

        if (!!rangeInfo || !!currentDate) {

            var params = {
                fromDate: rangeInfo ? rangeInfo.startStr : (currentDate ? currentDate.startStr : ""),
                toDate: rangeInfo ? rangeInfo.endStr : (currentDate ? currentDate.endStr : ""),
                userId: (user.hasOwnProperty('id') && user.id !== '') ? user.id : null,
                type: selectedType != 'all' ? selectedType : null,
                calendarView: calendarView,
            }

            dispatch(publicBookingList(params)).then((action) => {
                if (action.payload) {
                    console.log("publicBookingList", action.payload);
                    setBookings(action.payload);

                    if (!isLoadedCalendar) {
                        const api = calendarApi();
                        if (api) {
                            api.changeView(calendarView);
                        }
                        setIsLoadedCalendar(true);
                    }
                }
            });
        }

    }

    useEffect(() => {
        if (isLoaded) {

            var params = {
                fromDate: (currentDate ? currentDate.startStr : ""),
                toDate: (currentDate ? currentDate.endStr : ""),
                userId: (user.hasOwnProperty('id') && user.id !== '') ? user.id : null,
                type: selectedType != 'all' ? selectedType : null,
                calendarView: calendarView,
            }

            navigate("/crm/motorpool?calendarView=" + calendarView + "&type=" + params.type + "&fromDate=" + params.fromDate + "&toDate=" + params.toDate);
        }

        loadData();

    }, [selectedType, calendarView, isLoaded, currentDate]);

    const handleDateSelect = (selectInfo) => {
        const today = new Date().toISOString().split('T')[0];
        const selected = selectInfo.startStr;
        console.log("selectInfo", today, selected, selectInfo);
        if (today > selected) {
            swal({
                title: "Booking is unavailable for past dates!",
                icon: "warning",
                closeOnClickOutside: false,
            }).then(() => {
            });
        } else {
            swal({
                title: "Do you want to book?",
                icon: "info",
                buttons: ["Cancel", "Yes"],
                closeOnClickOutside: false,
            }).then((val) => {
                if (val) {
                    if (user.hasOwnProperty('id') && user.id !== '') {
                        navigate('/crm/motorpool/booking?date=' + selectInfo.startStr);
                    } else {
                        navigate('/crmlogin?newBooking=true&motorpool=true&date=' + selectInfo.startStr);
                    }
                }
            });

        }
    }

    return (
        <div className="flex flex-col flex-auto min-w-0 relative z-10 h-full">
            <CrmHeaderPublic />
            <Root
                contentToolbar={
                    <div className="px-12 w-full">
                        <CrmPublicBookingTabs menu='motorpool' />
                    </div>
                }
                content={
                    <div className="max-w-1366px m-auto">
                        <div className="flex p-24 container">
                            <motion.div
                                className="w-full"
                                initial={{ y: 20, opacity: 0 }}
                                animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                            >
                                {isLoaded && (
                                    <>
                                        <MotorpoolBookingCalendarHeader
                                            calendarRef={calendarRef}
                                            currentDate={currentDate}
                                            itemTypes={itemTypes}
                                            selectedType={selectedType}
                                            setSelectedType={setSelectedType}
                                            setCalendarView={setCalendarView}
                                        />
                                        <FullCalendar
                                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                            headerToolbar={false}
                                            initialView="dayGridMonth"
                                            selectable
                                            selectMirror
                                            dayMaxEvents
                                            weekends
                                            events={bookings}
                                            select={handleDateSelect}
                                            datesSet={handleDates}
                                            initialDate={calendarView == 'dayGridMonth' ? (initialDate.setDate(initialDate.getDate() + 10)) : initialDate}
                                            ref={calendarRef}
                                            eventBackgroundColor={(event) => renderEventBackgroundColor(event)}
                                        />
                                    </>
                                )}
                            </motion.div>
                        </div>
                    </div>
                }

            />
            <CrmFooter />
        </div>
    );
}

export default withReducer("crmApps", reducer)(MotorpoolBookingCalendar);
