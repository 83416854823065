import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { mutation, query } from "app/main/apps/residence-hall/graphql/house_graphql";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";

export const getHouse = createAsyncThunk(
    "residenceHallApp/house/getHouse",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getHouseByID,
            variables: {
                houseId: params.houseId,
            },
        });

        if (response.data.data.houseById) {
            var data = response.data.data.houseById;
            data.houseAdmins = (data.houseAdmins ? JSON.parse(data.houseAdmins) : []);

            return data === undefined ? null : data;
        }
    }
);

export const removeHouse = createAsyncThunk(
    "residenceHallApp/house/removeHouse",
    async (val, { dispatch, getState }) => {
        const { id } = getState().residenceHallApp.house;

        await axios.post(CONFIG.API + "/api/", {
            query: mutation.delete,
            variables: {
                id: id
            }
        });

        return id;
    }
);

const uploadImages = (uploadData, houseData, image) => {

    return new Promise(async (resolve, reject) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutationUpload.create,
            variables: {
                data: JSON.stringify(uploadData),
            },
        });

        if (response.data.data.createUpload.status == "success") {
            var upload = response.data.data.createUpload.upload;

            if (houseData.housePicId == image.image.id) {
                houseData.housePicPath = upload.path;
            }

            houseData.images[image.index].upload_id = upload.id;
            houseData.images[image.index].file_name = upload.fileName;
            houseData.images[image.index].file_id = upload.fileId;
            houseData.images[image.index].file_type = upload.fileType;
            houseData.images[image.index].path = upload.path;
            houseData.images[image.index].module = upload.module;
            houseData.images[image.index].url = "";

            resolve(houseData);

        }

    });

}

const saveImages = (houseData) => {

    return new Promise(async (resolve, reject) => {

        if (houseData.images.length) {

            var newImages = [];

            houseData.images.map(async (image, index) => {

                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image
                    })
                } else {
                    if (houseData.housePicId == image.fileId) {
                        houseData.housePicPath = image.path;
                    }
                }

            });

            if (newImages.length) {

                var tempCount = 0;

                newImages.map(async (image, index) => {

                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name: "residence-hall/house/house-" + randomString() + "-" + (image.image.file ? image.image.file.name : image.image.fileName)
                    }).then(async (result) => {

                        if (result.status === 200 && result.data.status === "success") {

                            var uploadData = result.data.uploadData
                            uploadData['module'] = 'residence-hall-house';
                            uploadData['file_id'] = image.image.id;

                            await uploadImages(uploadData, houseData, image).then(async (houseData) => {

                                if (houseData) {
                                    tempCount++;
                                    if (newImages.length == tempCount) {
                                        resolve(houseData);
                                    }
                                }

                            });
                        }


                    });

                });

            } else {
                resolve(houseData)
            }


        } else {

            resolve(houseData);

        }

    });

}

export const saveHouse = createAsyncThunk(
    "residenceHallApp/house/saveHouse",
    async (houseData, { dispatch, getState }) => {

        houseData.houseAdmins = (houseData.houseAdmins ? JSON.stringify(houseData.houseAdmins) : '');

        return await saveImages(houseData).then(async (houseData) => {

            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.save,
                variables: {
                    data: JSON.stringify(houseData),
                    id: houseData?.id,
                },
            });

            dispatch(
                showMessage({
                    message: "Hall has been successfully saved.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: response.data.data.saveHouse.status
                })
            );
            return response.data.data.saveHouse;

        });

    });

export const checkHouse = createAsyncThunk(
    "residenceHallApp/house/checkHouse",
    async (data, { dispatch, getState }) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.checkHouse,
            variables: { data: JSON.stringify(data) },
        });

        return response.data.data.checkHouse;
    }
);

const houseSlice = createSlice({
    name: "residenceHallApp/house",
    initialState: null,
    reducers: {
        resetHouse: () => null,
        newHouse: {
            reducer: (state, action) => action.payload,
            prepare: (event) => ({
                payload: {
                    id: null,
                    houseName: '',
                    houseDetails: '',
                    houseStatus: 'Enable',
                    houseAddress: '',
                    houseContact: '',
                    houseRoomNumber: '',
                    houseMother: '',
                    houseAdmins: [],
                    housePicId: '',
                    housePicPath: '',
                    images: []
                },
            }),
        },
    },
    extraReducers: {
        [getHouse.fulfilled]: (state, action) => action.payload,
        [saveHouse.fulfilled]: (state, action) => action.payload,
        [removeHouse.fulfilled]: (state, action) => null,
    },
});

export const { newHouse, resetHouse } = houseSlice.actions;

export default houseSlice.reducer;
