import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showMessage } from 'app/store/fuse/messageSlice';
import firebaseService from 'app/services/firebaseService';
import jwtService from 'app/services/jwtService';
import axios from "axios";
import { setUserData } from './userSlice';
import CONFIG from "app/config";
import { mutation, query } from "app/graphql/login_graphql";

export const submitLogin =
  ({ email, password }) =>
    async (dispatch) => {
      return await jwtService
        .signInWithEmailAndPassword(email, password)
        .then((user) => {         
          
          dispatch(setUserData(user));

          return dispatch(loginSuccess());
        })
        .catch((errors) => {
          errors.forEach((error) => {
            return dispatch(
              showMessage({
                message: error.message,
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                variant: "warning",
              })
            );
          });
        });
    };


export const submitLockScreen =
  (userPayload) =>
    async (dispatch) => {
      return jwtService
        .signUserPayload(userPayload)
        .then((user) => {
          jwtService.setSession(userPayload.token)
          dispatch(setUserData(user));
          return dispatch(loginSuccess());
        })
        .catch((errors) => {
          return dispatch(loginError(errors));
        });
    };

export const submitLoginWithFireBase =
  ({ email, password }) =>
    async (dispatch) => {
      if (!firebaseService.auth) {
        console.warn("Firebase Service didn't initialize, check your configuration");

        return () => false;
      }
      return firebaseService.auth
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          return dispatch(loginSuccess());
        })
        .catch((error) => {
          const emailErrorCodes = [
            'auth/email-already-in-use',
            'auth/invalid-email',
            'auth/operation-not-allowed',
            'auth/user-not-found',
            'auth/user-disabled',
          ];
          const passwordErrorCodes = ['auth/weak-password', 'auth/wrong-password'];
          const response = [];

          if (emailErrorCodes.includes(error.code)) {
            response.push({
              type: 'email',
              message: error.message,
            });
          }

          if (passwordErrorCodes.includes(error.code)) {
            response.push({
              type: 'password',
              message: error.message,
            });
          }

          if (error.code === 'auth/invalid-api-key') {
            dispatch(showMessage({ message: error.message }));
          }

          return dispatch(loginError(response));
        });
    };

export const forgotPassword = createAsyncThunk(
  "login/user/forgotpassword",
  async (postdata, { dispatch, getState }) => {

    const response = await axios.post(CONFIG.API + "/api/", {
      query: mutation.forgotPassword,
      variables: { data: JSON.stringify(postdata) },
    });


    return response.data.data.forgotPassword;
  }

);

export const checkLoginEmail =
  (filter) => async (dispatch) => {

    const response = await axios.post(CONFIG.API + "/api/", {
      query: query.checkLoginEmail,
      variables: filter
    });

    console.log(response.data.data.checkLoginEmail)

    if (response.data.data.checkLoginEmail.length) {
      return true;
    } else {
      return false;
    }

  };

const initialState = {
  success: false,
  errors: [],
};

const loginSlice = createSlice({
  name: 'auth/login',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.success = true;
      state.errors = [];

      setTimeout(() => {
        window.location.reload(); 
      }, 100);
    },
    loginError: (state, action) => {
      state.success = false;
      state.errors = action.payload;
    },
  },
  extraReducers: {},
});

export const { loginSuccess, loginError } = loginSlice.actions;

export default loginSlice.reducer;
