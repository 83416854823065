/*
* DateUpdated: 12/07/22
* updatedBy: ico
* Summary:  Check status > ISSUE: the color based on the inputted booking# didn't change -Daryl
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-41977-96
*/
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import format from 'date-fns/format';
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

import { getBookingStatus } from "app/main/booking/store/statusSlice";
import PrintStatusContent from "app/main/booking/print/PrintStatusContent";

const CardStyled = styled(Card)(({ theme }) => ({
    background: theme.palette.background.paper
}));

function StatusContent() {
    const mainTheme = useSelector(selectMainTheme);

    const [refNum, setRefNum] = React.useState("");
    const routeParams = useParams();
    const dispatch = useDispatch();

    const [form, setForm] = React.useState(null);
    const [noRecord, setNoRecord] = React.useState(false);
    const [checkRecord, setCheckRecord] = React.useState(true);

    React.useEffect(() => {

        if (routeParams.referenceNum != 'check') {
            setNoRecord(false)
            getBookingRefNum(routeParams.referenceNum)
        }

    }, [routeParams]);

    const handleSearchHandle = () => {
        setNoRecord(false)
        getBookingRefNum(refNum);
    }

    const getBookingRefNum = (refNum) => {
        dispatch(getBookingStatus(refNum)).then((response) => {
            if (response) {

                var tempEvents = [];

                var approvee = [];

                var approvedById = response['approvedById'] ? JSON.parse(response['approvedById']) : [];

                response['approvee'].map(function (val, k) {

                    var ifExist = approvedById.filter(function (v) {
                        return v.id == val.id;
                    });

                    if (ifExist.length) {
                        var status = "Approved";

                        if (response['status'] == 'Expired') {
                            status = "Expired";
                        }

                        if (response['status'] == 'Rejected') {
                            status = "Rejected";
                        }

                        approvee.push({
                            ...val,
                            status: response['status'],
                            dateApproval: ifExist[0].date
                        })
                    } else {
                        approvee.push({
                            ...val,
                            status: response['status'],
                        })
                    }
                });

                var tempEventsVal = {
                    ...response,
                    approvee: approvee
                };

                setForm(tempEventsVal);
            }

            if (tempEventsVal) {
                setNoRecord(false)

            } else {
                setNoRecord(true)
            }
            if (setForm(tempEventsVal)) {
                setCheckRecord(false)
            } else {
                setCheckRecord(false)
            }
        })
    }

    const printRef = useRef();

    const handelClickPrint = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <>
            <div className="flex flex-1 w-full items-center justify-between">
                <div className="flex flex-1 items-center justify-center px-16">
                    <ThemeProvider theme={mainTheme}>
                        <TextField
                            className=" min-w-160 w-320"
                            onChange={(ev) => {
                                setRefNum(ev.target.value)
                                setNoRecord(false)
                                setCheckRecord(true)
                                setForm(false)
                            }}
                            sx={{
                                "& .MuiInputBase-root": {
                                    height: 40,
                                },
                                "& .MuiOutlinedInput-root": {
                                    px: 1, py: 0
                                }
                            }}
                            placeholder="Enter Reference Number"
                            variant="outlined"

                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon color="action">search</Icon>
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Button
                            className="w-96 max-h-40 rounded-md ml-10 py-9 px-10 truncate"
                            variant="contained"
                            onClick={handleSearchHandle}
                        >
                            Check Status
                        </Button>

                    </ThemeProvider>
                </div>
            </div>
            {(checkRecord &&
                <div className='flex justify-center my-16'>
                    <img className="mt-14" src="assets/images/backgrounds/check-status.png" loading='lazy' />
                </div>
            )}
            {(form &&
                <div className="w-full">
                    <div className="p-9 pb-0 w-fit max-w-lg min-w-800 m-auto mb-48">

                        <CardStyled className="flex flex-col shadow-0 mt-10" elevation={0}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                                    <DialogContent classes={{ root: 'py-10 px-10 pb-0 ' }}>

                                        <div className=" flex flex-col justify-center mx-16">
                                            <Typography component="div" className=' text-2xl font-semibold text-center'>
                                                {form.itemType} Booking for {form.name}
                                            </Typography>
                                            <div className='text-15 text-center font-semibold'>
                                                <Typography component="div"
                                                    className={"my-2 rounded-md font-bold tracking-wide text-14 p-1 py-10 " +
                                                        (form.status == "Rejected" ? "bg-red-50 text-red-600" : "") +
                                                        (form.status.includes("Approved") ? "bg-blue-50 text-blue-600  " : "") +
                                                        (form.status == "Expired" ? "bg-gray-100 text-gray-600  " : "") +
                                                        (form.status == "Pending" ? "bg-yellow-50 text-yellow-800 " : "") +
                                                        (form.status.includes("In-Use") ? "bg-orange-50 text-orange-600" : "") +
                                                        (form.status == "Done" ? "bg-green-50 text-green-600 " : "") +
                                                        (form.status == "For Return" ? "bg-gray-100 text-gray-600" : "")
                                                    }>
                                                    Your request for booking is {form.status}
                                                </Typography>
                                            </div>
                                        </div>

                                        <div className="flex justify-between items-center mx-16">
                                            <Typography className='text-14 font-semibold'>
                                                Booking Reference #: {form.referenceNum}
                                            </Typography>

                                            <IconButton
                                                color="inherit" className="rounded-0 py"
                                                onClick={handelClickPrint}
                                            >
                                                <Icon color="inherit">print</Icon>
                                            </IconButton>
                                        </div>
                                        <div className='flex flex-row'>
                                            <div className='flex flex-col mx-16'>
                                                <div className='flex'>
                                                    <ImageList sx={{ width: 120, height: 120 }} cols={1} className="overflow-hidden rounded-md">
                                                        <ImageListItem>
                                                            <img className="max-w-none w-auto h-full" src={form.itemPhotoUrl ? form.itemPhotoUrl : 'assets/images/ecommerce/product-image-placeholder.png'} alt={form.name} loading="lazy" />
                                                        </ImageListItem>
                                                    </ImageList>
                                                </div>
                                            </div>
                                            <div className='flex flex-col mx-16 relative'>
                                                <Typography className='text-14 font-semibold'>
                                                    {form.itemCode}
                                                </Typography>
                                                <Typography className=' text-18 font-bold'>
                                                    {form.itemName}
                                                </Typography>
                                                <div className='absolute bottom-0 truncate'>
                                                    <Typography className='text-15 font-bold'>
                                                        <span className="text-15 font-medium">CMI Rate :</span> ${form.itemRate} / (30 mins)
                                                    </Typography>
                                                    <Typography className='text-15 font-bold'>
                                                        <span className="text-15 font-medium">Total :</span> ${form.total}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex flex-row justify-between mt-24 mb-16'>
                                            <div className='flex flex-col mx-16 '>
                                                <Typography className=' text-14 font-semibold mt-10'>
                                                    <span className=" text-14 font-medium">Name :</span> {form.name}
                                                </Typography>
                                                <Typography className=' text-14 font-semibold'>
                                                    <span className=" text-14 font-medium">Email :</span> {form.email}
                                                </Typography>
                                                <Typography className=' text-14 font-semibold'>
                                                    <span className=" text-14 font-medium">Contact :</span> {form.contact}
                                                </Typography>
                                                <Typography className=' text-14 font-semibold'>
                                                    <span className=" text-14 font-medium">Department :</span> {form.department}
                                                </Typography>
                                            </div>
                                            <div className='flex flex-col mx-16'>
                                                <Typography className=' text-14 font-semibold mt-10'>
                                                    <span className=" text-14 font-medium">Start :</span> {form.start ? format(new Date(form.start), 'EEEE, MMMM dd, yyyy') : ""} {form.start ? format(new Date(form.start), 'hh:mm a') : ""} - {form.end ? format(new Date(form.end), 'hh:mm a') : ""}
                                                </Typography>
                                                <Typography className=' text-14 font-semibold'>
                                                    <span className=" text-14 font-medium">Duration : </span> {form.duration}
                                                </Typography>
                                                <Typography className=' text-14 font-semibold'>
                                                    <span className=" text-14 font-medium">Time of Booking :</span> {form.timeOfBooking ? format(new Date(form.timeOfBooking), 'MMMM dd, yyyy hh:mm a') : ""}
                                                </Typography>
                                            </div>
                                        </div>

                                        <div className='flex flex-col mx-16 mb-24'>
                                            <Typography className=' text-14 font-semibold'>
                                                Purpose
                                            </Typography>
                                            <Typography className='text-14  '>
                                                {form.purpose}
                                            </Typography>
                                        </div>

                                        <div className='flex flex-col mx-16 mb-24'>
                                            <Typography className=' text-14 font-semibold'>
                                                Approved by:
                                            </Typography>

                                            {
                                                form.approvee.map(function (approvee) {
                                                    return (
                                                        <div className='flex flex-row justify-between' key={approvee.id}>
                                                            <div className='flex flex-col mx-3 '>
                                                                <Typography className=' text-14 font-semibold mt-10'>
                                                                    <span className=" text-14 font-medium">Name :</span> {approvee.name}
                                                                </Typography>
                                                                <Typography className=' text-14 font-semibold'>
                                                                    <span className=" text-14 font-medium">Department :</span> {approvee.department}
                                                                </Typography>
                                                                <Typography className=' text-14 font-semibold'>
                                                                    <span className=" text-14 font-medium">Position :</span> {approvee.position}
                                                                </Typography>
                                                                <Typography className=' text-14 font-semibold'>
                                                                    <span className=" text-14 font-medium">Date of Approval :</span> {approvee.dateApproval}
                                                                </Typography>
                                                                <Typography className=' text-14 font-semibold'>
                                                                    <span className=" text-14 font-medium">Status :</span> {approvee.status}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>


                                    </DialogContent>
                                </Grid>
                            </Box>
                        </CardStyled>


                    </div>

                    <PrintStatusContent printRef={printRef} form={form} />
                </div>
            )}

            {((noRecord) &&
                <div className="w-full">
                    <div className="p-9 pb-0 w-fit max-w-lg min-w-800 m-auto mb-48">

                        <CardStyled className="flex flex-col shadow-0 mt-10 rounded-md bg-red-50" elevation={0}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                                    <DialogContent classes={{ root: 'py-10 px-10' }}>
                                        <div className=" flex justify-center mx-16">
                                            <Typography className='text-xl font-semibold text-red-700'>
                                                Reference Number {refNum} not found!
                                            </Typography>
                                        </div>
                                    </DialogContent>
                                </Grid>
                            </Box>
                        </CardStyled>
                        <div className='flex justify-center my-16'>
                            <img className="mt-14" src="assets/images/backgrounds/check-status.png" loading='lazy' />
                        </div>
                    </div>
                </div>
            )}
        </>
    );

}

export default StatusContent;